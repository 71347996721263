<div class="container-fluid m-3">
    <div class="filters">

        <mat-card class="mat-elevation-z3">
            <mat-card-title>Excepciones por Vuelo</mat-card-title>
            <mat-card-subtitle>Selecciona y modifica los datos según requerimiento</mat-card-subtitle>
            <mat-card-content>
                <form >
                    <div class="fields">
                        <div class="fields row g-3">
                            <div class="col-md-3">
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input type="text" matInput  #searchi>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
    
                            </div>
    
                        </div>
                        <div class="col-md-3">
                            <div class="col-md-3" style="padding-top: 5px;">
                                <button mat-flat-button color="accent" (click)="search(searchi.value)">
                                    Buscar
                                </button>
                            </div>
    
                        </div>
    
    
                        <div class="action">
    
                            <div class="search" *ngIf="isAdmin">
                                <button mat-flat-button color="primary" (click)="openEdit(null)">
                                    + Nueva Excepción
                                </button>
                            </div>
                        </div>
    
                    </div>
    
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-content class="p-2">
                <div class="body">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort cdkDropList
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">

                            <ng-container matColumnDef="codigoLineaAerea">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Código línea aerea </th>
                                <td mat-cell *matCellDef="let element"> {{element.codigoLineaAerea}} </td>
                            </ng-container>

                            <ng-container matColumnDef="numeroVuelo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Número de Vuelo </th>
                                <td mat-cell *matCellDef="let element"> {{element.numeroVuelo}} </td>
                            </ng-container>


                            <ng-container matColumnDef="codigoAtoDesde">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Origen </th>
                                <td mat-cell *matCellDef="let element"> {{element.codigoAtoDesde}} </td>
                            </ng-container>

                            <ng-container matColumnDef="codigoAtoHacia">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Destino </th>
                                <td mat-cell *matCellDef="let element"> {{element.codigoAtoHacia}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flotasMm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Sub Flota </th>
                                <td mat-cell *matCellDef="let element"> 
                                    {{element.flotasMm.grupoFlota}} </td>
                            </ng-container>

                            <ng-container matColumnDef="mapa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Mapa </th>
                                <td mat-cell *matCellDef="let element"> {{element.mapa.codigoMapa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="configuracion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Configuración </th>
                                <td mat-cell *matCellDef="let element"> {{element.mapa.descripcionMapa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="inicioExcepcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Inicio excepción </th>
                                <td mat-cell *matCellDef="let element"> {{element.inicioExcepcion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="terminoExcepcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Termino excepción </th>
                                <td mat-cell *matCellDef="let element"> {{element.terminoExcepcion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="cabina">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Cabina </th>
                                <td mat-cell *matCellDef="let element"> {{element.mapa.tiposCabina.descripcion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="action" *ngIf="isAdmin">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="format-actions">
                                        <div class="example-button-container">
                                            <button mat-stroked-button color="primary"
                                                (click)="openEdit(element)">
                                                Editar
                                            </button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div class="example-button-container">
                                                <button mat-stroked-button color="warn"
                                                    (click)="delete(element)">
                                                    Eliminar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>

</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">Loading... </p>
</ngx-spinner>

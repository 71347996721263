<div class="row mt-4">
    <div class="col-12">
        <mat-card >
            <mat-card-title>Subir Archivo</mat-card-title>
            <mat-card-content class="p-sm-1">
                <form [formGroup]="uploadForm">
                    <div class="col-sm-4">
                        <br>
                        <div class="row">
                        <p >Seleccione un archivo para enviar</p>
                        </div>
                        <div class="row">
                          <div class="custom-file">

                                <label class="att-each" >
                                    <input lang="en" type="file" class="custom-file-input" formControlName="fileUpload" id="customFile"
                                                                (change)="selectFiles($event)">
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row p-lg-5">
                        <div class="list-group">
                            <ul class="list-group list-group-flush"
                                    *ngFor="let file of uploadFiles">
                                <li class="list-group-item align-top">
                                    <span class="icon-posit material-icons">insert_drive_file</span>
                                    <span class="text-label">{{ file.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                   <div class="row text-left mt-4">
                        <div class="col-1">
                            <button [disabled]="loading || !uploadForm.valid" mat-flat-button color="accent" (click)="sendRequest()">
                                <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                Enviar
                            </button>
                        </div>
                        <div class="col-1">&nbsp;</div>
                        <div class="col-10">
                            <button mat-flat-button color="accent" (click)="clearForm()">                                
                                Cancelar
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white">Loading... </p>
        </ngx-spinner>
    </div>
</div>



export class UserInfo {

  roles: string[];

  constructor(roles: string[]) {
    this.roles = roles;
  }

  public isViewerUser(): boolean {
    return this.roles.some(rol => rol === 'VIEWER');
  }

  public isAdminUser(): boolean {
    return this.roles.some(rol => rol === 'ADMIN');
  }

  public isAdminSftpUser(): boolean {
    return this.roles.some(rol => rol === 'ADMIN_SFTP');
  }

  public isViewerSftpUser(): boolean {
    return this.roles.some(rol => rol === 'VIEWER_SFTP');
  }

  public isUploadSftpUser(): boolean {
    return this.roles.some(rol => rol === 'UPLOAD_SFTP');
  }

  public isViewerJTA(): boolean {
    return this.roles.some(rol => rol === 'VIEWER_JTA');
  }

}

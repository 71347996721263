import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { JsonExporterService, TxtExporterService } from 'mat-table-exporter';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PendingService } from 'src/app/services/pending.service';
import { PendingHistoryRequest } from 'src/app/models/pending/hist-request';
import { BatchProcessResponse } from 'src/app/models/pending/batch-process-response';
import { BatchProcessRequest } from 'src/app/models/pending/batch-process-request';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  element: HTMLElement;
  formGroup: FormGroup = new FormGroup({
    startDate: new FormControl("", Validators.required),
    endDate: new FormControl("", Validators.required),
    typeObservation: new FormControl(""),
    state: new FormControl("")
  });;

  displayedColumns: string[] = ['codigoLineaAerea', 'flightDate', 'fechaEjecucion',
    'flightNumber', 'dprtrAirport', 'arrvlAirport', 'matricula', 'observacion',
    'detalleObservacion'];
  dataSource: MatTableDataSource<any>;
  request: PendingHistoryRequest;
  request_update: BatchProcessRequest={ idBatch: 1,  lockId: 1};

  excelName: string;
  loading: boolean = false;
  typeObservations: string[] = this.constants.TYPE_OBS;
  type: string = "OK"
  status: string[] = this.constants.STATUS;

  minDateInit: Date;
  minDateEnd: Date;
  maxDateEnd: Date;

  pendingAuto = false;

  selected = 'ALL';
  ngxSpinner: string = "Loading...";

  constructor(
    private constants: Constants,
    private service: PendingService, 
    private expService: JsonExporterService,
    private spinnerService: NgxSpinnerService
  ) {
    this.excelName = new Date().toISOString().slice(0, 10).replaceAll('-', '') + '-pending-history';
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>([]);
    this.request = new PendingHistoryRequest();

    this.service.findBatchProcess('1').subscribe(
      (data: BatchProcessResponse) => {
        if( data.lockId == 1){
          this.pendingAuto= true;
        }
      },
      (err) => {
        console.log('Error recuperando información de historico.', err);
        this.spinnerService.hide();
        if(err.status == 400){
          SweetAlertUtils.show(`No se pudo recuperar la información de Pending Automático`, 'info', 'Info');
        }else{
          SweetAlertUtils.showError(err, this.constants.LOAD_ERROR);
        }
        
      }
    );

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  search() { 
    console.log('search');
    this.spinnerService.show();
   
    if (!this.formGroup.valid) {
      this.spinnerService.hide();
      return;
    }

    this.request.startDateTime = this.formGroup.controls.startDate.value.toISOString().slice(0, 10);
    this.request.endDateTime = this.formGroup.controls.endDate.value.toISOString().slice(0, 10);
    this.request.typeObservation = (this.formGroup.controls.typeObservation.value==="")?'ALL':this.formGroup.controls.typeObservation.value;

    this.service.findHistory(this.request).subscribe(
      (data: any) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinnerService.hide();
      },
      (err) => {
        console.log('Error recuperando información de historico.', err);
        this.spinnerService.hide();
        if(err.status == 400){
          SweetAlertUtils.show(`No existen registros para los filtros seleccionados.`, 'info', 'Info');
        }else{
          SweetAlertUtils.showError(err, this.constants.ALTERATIONS_PROCESS_ERROR);
        }
        
      }
    );

    //this.dataSource.filter = '17';

  }

  updatePendingAuto(event:any) {
    let activate:boolean = !event._checked;
    let activate_Tx:string = (!event._checked)?'Activar':'Desactivar';


    SweetAlertUtils.confirm(`¿Quieres ${activate_Tx} el Pending Automático?`).then((result) => {
      if (result.value) {
        this.spinnerService.show();
        this.request_update.lockId = (activate)?1:0;
        this.service.updatePendingAuto(this.request_update).subscribe(
          (data: BatchProcessResponse) => {
            console.log(data);
            this.pendingAuto = activate;
            this.spinnerService.hide();
          },
          (err) => {
            console.log('Error no se pudo realizar el update.', err);
            this.spinnerService.hide();
            event._checked = this.pendingAuto;
            if(err.status == 400){
              SweetAlertUtils.show(`No se pudo realizar la actualización`, 'info', 'Info');
            }else{
              SweetAlertUtils.showError(err, this.constants.LOAD_ERROR);
            }
            
          }
        );        
      }else{
        event._checked = this.pendingAuto;
      }
    });

   }

 hiddenElements(): void {
    console.log('Export Started');
    this.ngxSpinner = "Downloading...";
    this.spinnerService.show();
  }
  showElements(): void {
    console.log('Export Completed');
    this.spinnerService.hide();
    this.ngxSpinner = "Loading...";
  }

  
  onDateEndChange(event) {
    this.minDateInit = new Date(event.value)
    this.minDateInit.setMonth(this.minDateInit.getMonth() - 1);
  }

  onDateInitChange(event) {
    this.minDateEnd = event.value;
    this.maxDateEnd = new Date(event.value);
    this.maxDateEnd.setMonth(this.maxDateEnd.getMonth() + 1)
  }

}

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from "../../common/constants";
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SftpAircomSitaResponse } from '../../models/sftp-aircom-sita/sftp-aircom-sita-response';
import { SftpAircomSitaRequest } from '../../models/sftp-aircom-sita/sftp-aircom-sita-request';
import { SftpAircomSitaService } from "../../services/sftp-aircom-sita.service"
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DirectoryNode } from '../../models/sftp-aircom-sita/DirectoryNode';
import { SftpAircomSitaTree } from '../sftp-aircom-sita-tree/sftp-aircom-sita-tree.component';
@Component({
  selector: 'app-sftp-aircom-sita',
  templateUrl: './sftp-aircom-sita.component.html',
  styleUrls: ['./sftp-aircom-sita.component.scss']
})

export class SftpAircomSitaComponent implements OnInit,AfterViewInit {
  
  sftpForm = new FormGroup({
      pathCtrl: new FormControl('', Validators.required)
    });
  
  globalFilter = '';
  FLIGHTS_DATA: SftpAircomSitaResponse[] = [];
  pathOptions: string[]= [];
  filteredOptions: Observable<string[]>;
  displayedColumns: string[] = ['select','fileName'];
  dataSource: MatTableDataSource<SftpAircomSitaResponse>;
  selection = new SelectionModel<SftpAircomSitaResponse>(true, []);
  request: SftpAircomSitaRequest;
  ngxSpinner: string = "Loading...";
  directories : DirectoryNode[] = [];
  fullPath: string = '';
  selectedPath: string = '';
  treeMessage: string = '';
  timer: any;

  filteredValues = {
    id: '', path: '', fileName: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("treeComponent") treeComponent : SftpAircomSitaTree;

  constructor(private constants: Constants,
    private service: SftpAircomSitaService,
    private spinnerService: NgxSpinnerService) {

    }

  ngOnInit(): void {
    
    this.request = new SftpAircomSitaRequest();
    this.dataSource = new MatTableDataSource<SftpAircomSitaResponse>(this.FLIGHTS_DATA);

    this.filteredOptions = this.sftpForm.controls.pathCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );  

      this.initialize();
      this.getDirectories(false);
      this.timer = setInterval(() => {this.getDirectories(false); }, 360000);
      
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.pathOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngAfterViewInit() { 
  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  getDirectories(forceUpdate: boolean){
    if(forceUpdate){
      this.spinnerService.show();
    }
    
    this.service.getDirectoriesFromSftp(forceUpdate).subscribe(
      (data: string[]) => {
        this.pathOptions = [];
        this.pathOptions = this.pathOptions.concat(data);
        if(data && data.length > 0){
          this.getTreePath();
        }
        else{
          this.treeComponent.directories = [];
          this.treeComponent.setTreeMessage(this.constants.SFTP_ERROR_LOADING_DIRECTORIES);
          SweetAlertUtils.show(this.constants.SFTP_ERROR_LOADING_DIRECTORIES, 'info', 'Info');   
        }
        
        if(forceUpdate){
          this.spinnerService.hide();
        }
      },
      (err) => {
        console.log('Error recuperando información del SFTP.', err);
        if(forceUpdate){
          this.spinnerService.hide();
        }
        if(err.status == 400){
          SweetAlertUtils.show(this.constants.SFTP_ERROR_LOADING_DIRECTORIES, 'info', 'Info');          
        }else{
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
        this.treeComponent.directories = [];
        this.treeMessage = this.constants.SFTP_ERROR_LOADING_DIRECTORIES;
      }
    );
  }

  getTreePath(){
    this.directories = [];
    this.pathOptions
      .map(path => path.split('/').slice(1))
      .reduce((dir, sub) => this.insert(dir, sub), this.directories);

    this.treeComponent.dataSourceTree.data = this.directories;
    this.treeComponent.setExpandedNodes(this.sftpForm.controls.pathCtrl.value);
  }

  // Insert path into directory tree structure:
  insert(children: DirectoryNode[] = [], [head, ...tail]:string[]) {
    let child = children.find(child => child.name === head);
    tail = tail.filter(value => value.trim().length > 0)

    if(this.fullPath.endsWith("/")){
      this.fullPath= this.fullPath.concat(head).concat("/");
    }
    else{
      this.fullPath= this.fullPath.concat("/").concat(head).concat("/");
    }

    if (!child){
      children.push(child = {name: head, path: this.fullPath, children: []}); 
    } 
    if (tail.length == 0){
      this.fullPath = '';
    }
    if (tail.length > 0){
      this.insert(child.children, tail);
    } 
    return children;
  }

  fillTableTest(){
    this.FLIGHTS_DATA = [];
    this.FLIGHTS_DATA.push({id:1, fileName: "file1", path:"parent1/child1"});
    this.FLIGHTS_DATA.push({id:2, fileName: "file2", path:"parent2/child2"});
    this.FLIGHTS_DATA.push({id:3, fileName: "file3", path:"parent3/child3"});
    this.FLIGHTS_DATA.push({id:4, fileName: "file4", path:"parent4/child4"});
    this.FLIGHTS_DATA.push({id:5, fileName: "file5", path:"parent5/child5"});
    this.FLIGHTS_DATA.push({id:6, fileName: "file6", path:"parent6/child6"});
  }

  clean(): void {
 
    this.sftpForm.reset();  
    this.filteredOptions = this.sftpForm.controls.pathCtrl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.treeComponent.clearTree();
    this.initialize();
    
  }

  initialize(): void{
    this.request = new SftpAircomSitaRequest();
    this.dataSource = new MatTableDataSource<SftpAircomSitaResponse>([]);
    this.selection.clear();
    
    this.globalFilter = '';
    this.selectedPath = '';
  }

  search(): void {
    
    this.spinnerService.show();
    this.initialize();
    this.selectedPath = this.sftpForm.controls.pathCtrl.value;
    // this.fillTableTest();
    this.dataSource = new MatTableDataSource<SftpAircomSitaResponse>(this.FLIGHTS_DATA);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
    this.request.sftpPath = this.validListValue(this.sftpForm.controls.pathCtrl.value);
    this.setSftpAircomSita(this.request);
    
  }

  setSftpAircomSita(request:SftpAircomSitaRequest){
    this.service.findSftpFilesByPath(request).subscribe(
      (data: any) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinnerService.hide();
      },
      (err) => {
        this.spinnerService.hide();
        if(err.status == 400){
          SweetAlertUtils.show(`No se encontraron archivos en el directorio seleccionado.`, 'info', 'Info');          
        }else{
          console.log('Error recuperando información del SFTP.', err);
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }

      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  hiddenElements(): void {
    console.log('Export Started');
    this.ngxSpinner = "Downloading...";
  }

  showElements(): void {
    console.log('Export Completed');    
    this.ngxSpinner = "Loading...";    
  }

  validListValue(value: any) {
    if (value) {
      return value;
    } else {
      return [];
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: SftpAircomSitaResponse): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  download(): void {

    if(this.dataSource.data.length == 0){      
      SweetAlertUtils.show(`Seleccione ruta a descargar.`, 'info', 'Info');  
    }else if(this.selection.selected.length == 0){      
      SweetAlertUtils.show(`Seleccione archivos a descargar.`, 'info', 'Info'); 
    }else{
      this.spinnerService.show();
      let listaSftp: Array<{fileName: string}>;
      listaSftp = new Array();    
      let path: string;
      
      this.selection.selected.forEach((sftp, index) => {
        path = sftp.path;
        listaSftp.push({fileName:sftp.fileName});
      });
      this.request.sftpPath = path;
      this.request.listFileName = listaSftp;    
      
      this.service.findDownloadFilesByPath(this.request).subscribe(
        (resp) =>{        
          this.spinnerService.hide();
          let b:any = new Blob([resp], { type: 'application/zip' });
          var url= window.URL.createObjectURL(b);
          window.open(url);
        },
        (err) => {
          console.log('Error recuperando información del SFTP.', err);
          this.spinnerService.hide();
          if(err.status == 400){         
            SweetAlertUtils.show(`No existen registros para los filtros seleccionados.`, 'info', 'Info');         
          }else{
            SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
          }
  
        }
      );
    }
  }

  applyFilter(filter) {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  getSelectedPath(selectedPath: string){
    this.sftpForm.controls.pathCtrl.setValue(selectedPath);
  }


}
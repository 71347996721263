export class AlterOperRequest{
    types?: string[];
    departure?: string;
    arrival?: string;
    flightNumber?: string;
    fromDate: string;
    toDate: string;
    changeManual?: string[];
    status?: string[];
}

export class ChangeManual {
    idAlterationOperational: number;
    flagManual: boolean;
    userEmail?: string;
  }
<div class="container-fluid m-3">

    <div class="filters">

        <mat-card class="mat-elevation-z3">
            <mat-card-title>Histórico Pending
                <span class="right">
                    <section class="example-section">
                        <mat-label class="clean">Pending Automatico </mat-label>
                        <mat-slide-toggle class="example-margin" color="primary" [checked]="pendingAuto" (click)="updatePendingAuto(pending)" #pending></mat-slide-toggle>
                    </section>
                </span>
            </mat-card-title>
            <mat-card-subtitle>Selecciona uno o mas campos para realizar la búsqueda</mat-card-subtitle>
            <mat-card-content>
                <form [formGroup]="formGroup">

                    <div class="fields row g-3">
                        <div class="col-md-2">
                            <mat-form-field class="mr-3">
                                <mat-label>Start Date</mat-label>
                                <input matInput formControlName="startDate" [matDatepicker]="startDate"
                                    [min]="minDateInit" (click)="startDate.open()"
                                    (dateChange)="onDateInitChange($event)" readonly />
                                <mat-datepicker-toggle matSuffix [for]="startDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #startDate disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field class="mr-3">
                                <mat-label>End Date</mat-label>
                                <input matInput formControlName="endDate" [matDatepicker]="endDate" [min]="minDateEnd"
                                    [max]="maxDateEnd" (click)="endDate.open()" (dateChange)="onDateEndChange($event)"
                                    readonly />
                                <mat-datepicker-toggle matSuffix [for]="endDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #endDate disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field class="mr-3 pb-lg-2">
                                <mat-label>Tipo pending</mat-label>
                                <mat-select formControlName="typeObservation" [(value)]="selected">                                    
                                    <ng-container *ngIf="typeObservations">
                                        <mat-option value="ALL">ALL</mat-option>
                                        <mat-option *ngFor="let type of typeObservations; index as i;" [value]="type">
                                            {{type}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-1">
                            &nbsp;
                        </div>
                        <div class="col-md-2 buttons">
                            <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                                <span *ngIf="false" id="download-icon" class="material-icons">refresh</span>
                                Buscar
                            </button>
                        </div>
                        <div class="col-md-3 buttons">
                            <button mat-flat-button color="accent" [disabled]="!(this.dataSource.data.length > 0)" 
                                (click)="exporter.exportTable('xlsx', {fileName:excelName, sheet: 'resultado', Props: {Author: 'Latam'}})">
                                <span class="material-icons">file_download</span>
                                Descargar documento
                            </button>

                        </div>
                    </div>


                    <!--div class="action">
                            <div class="search">
                                <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                                    <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                    Buscar
                                </button>
                            </div>
                        </div-->

                </form>
            </mat-card-content>
        </mat-card>

    </div>

    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-content class="p-2">
                <div class="body">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort cdkDropList
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matTableExporter
                            #exporter="matTableExporter" [hiddenColumns]="[10]" (exportStarted)="hiddenElements()"
                            (exportCompleted)="showElements()">

                            <ng-container matColumnDef="codigoLineaAerea">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Línea aérea </th>
                                <td mat-cell *matCellDef="let element"> {{element.codigoLineaAerea}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flightNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Número Vuelo </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.flightNumber}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dprtrAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Origen </th>
                                <td mat-cell *matCellDef="let element"> {{element.dprtrAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="arrvlAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Destino </th>
                                <td mat-cell *matCellDef="let element"> {{element.arrvlAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flightDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    vuelo UTC </th>
                                <td mat-cell *matCellDef="let element"> {{element.flightDate}} </td>
                            </ng-container>

                            <ng-container matColumnDef="fechaEjecucion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    ejecución </th>
                                <td mat-cell *matCellDef="let element"> {{element.fechaEjecucion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="matricula">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Matricula </th>
                                <td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
                            </ng-container>

                            <ng-container matColumnDef="observacion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> TIpo </th>
                                <td mat-cell *matCellDef="let element"> {{element.observacion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="detalleObservacion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Detalle </th>
                                <td mat-cell *matCellDef="let element"> {{element.detalleObservacion}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>


</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">{{ngxSpinner}}</p>
</ngx-spinner>
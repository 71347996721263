<style>
  :host {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Excepción por Rango vuelo</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" class="row g-3" (ngSubmit)="onSubmit(myForm)" novalidate>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Código línea aerea</mat-label>
        <input matInput [(ngModel)]="data.row.codigoLineaAerea" name="codigoLineaAerea" required
         maxlength="2" (ngModelChange)="data.row.codigoLineaAerea= $event.toUpperCase()">
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>SubFlota</mat-label>
        <mat-select [(value)]="data.row.flotasMm.idFlotaMm" [(ngModel)]="data.row.flotasMm.idFlotaMm" name="idFlotaMm" required>
          <mat-option *ngFor="let subflota of data.subFlotas" [value]="subflota.idFlotaMm">
            {{subflota.grupoFlota}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Rango Desde</mat-label>
        <input matInput [(ngModel)]="data.row.desde" name="desde" required
         type="number" maxlength="10" min="0">
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Rango Hasta</mat-label>
        <input matInput [(ngModel)]="data.row.hasta" name="hasta" required
         type="number" maxlength="10" [min]="data.row.desde+1">
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Cabina</mat-label>
        <mat-select [(ngModel)]="data.row.mapa.tiposCabina.idCabina"
          name="idCabina" (selectionChange)="selectCabin($event.value)">
          <mat-option *ngFor="let cabinType of data.cabinTypes" [value]="cabinType.idCabina">
            {{cabinType.descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="fill">
        <mat-label>Mapa</mat-label>
        <mat-select [(ngModel)]="data.row.mapa" name="idMapa"
          [formControl]="mapaCtrl" #matSelectMapa required>
          <mat-option>
            <ngx-mat-select-search [formControl]="mapaFilterCtrl"
            placeholderLabel="Buscando Mapa..."
            noEntriesFoundLabel="'no existen coincidencias'" ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let mapa of (filteredMapas | async ) || []" [value]="mapa">
            {{converString(mapa?.codigoMapa)}} ({{converString(mapa?.descripcionMapa)}})
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>
 
  </form>

</div>
<div mat-dialog-actions>
  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between" style="width: 470px;">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data"  [disabled]="!myForm.valid"><!--cdkFocusInitial-->
        Guardar</button>
    </div>
  </div>


</div>

<div class="container-fluid m-4">
    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-title>Ejecución Manual
                <span class="right">
                    <p class="description">Actualizar</p>
                    <div class="loader" *ngIf="isLoading"></div>
                    <div class="example-button-container" *ngIf="!isLoading">
                        <button mat-mini-fab color="primary" aria-label="Refrescar" (click)="refresh()">
                          <mat-icon>sync</mat-icon>
                        </button>
                    </div>
                </span>
            </mat-card-title>
            <mat-card-content class="p-2">
                <div class="options d-flex bd-highlight justify-content-between mb-3">
                    <div class="p-2 bd-highlight">
                        <div class="rectangle">
                            <div class="hoja"></div>
                            <div class="d-flex flex-column positiona">
                                <div class="text-green">Cantidad Pending</div>
                                <div class="title-green">{{dataSource.data.length}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div [ngClass]="{'rectangle-red':executing, 'rectangle':!executing}">
                            <div [ngClass]="{'reloj-red':executing, 'reloj':!executing}"></div>
                            <div class="d-flex flex-column positionb">
                                <div [ngClass]="{'text-red':executing, 'text-green':!executing}">{{titleExecute}}</div>
                                <div [ngClass]="{'title-red':executing, 'title-green':!executing}">{{time}}</div>
                                <div [ngClass]="{'text-date-red':executing, 'text-date-green':!executing}">{{date}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 align-self-end bd-highlight">
                        <button mat-flat-button color="accent" [disabled]="executing" (click)="execute()">
                            <!--span *ngIf="true" id="download-icon" class="material-icons">refresh</span-->
                            Ejecutar Proceso
                        </button>
                    </div>
                </div>

                <div class="body">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort cdkDropList
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matTableExporter
                            #exporter="matTableExporter" [hiddenColumns]="[10]">

                            <ng-container matColumnDef="carrierComercial">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Línea aérea </th>
                                <td mat-cell *matCellDef="let element"> {{element.carrierComercial}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flightNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Vuelo </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.flightNumber}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dprtrAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Origen </th>
                                <td mat-cell *matCellDef="let element"> {{element.dprtrAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="arrvlAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Destino </th>
                                <td mat-cell *matCellDef="let element"> {{element.arrvlAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="scheduledFlightDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    Programada<br />(UTC) </th>
                                <td mat-cell *matCellDef="let element"> {{element.scheduledFlightDate}} </td>
                            </ng-container>

                            <ng-container matColumnDef="matricula">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Matricula </th>
                                <td mat-cell *matCellDef="let element"> {{element.aircraftRegistration}} </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

</div>
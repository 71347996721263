<!--Loading-->
<app-loading *ngIf="isLoading"></app-loading>
<!--Loading-->
<div class="login-container">
  <div class="row my-auto justify-content-end">
    <div class="col-12 col-sm-10 col-md-8">
      <mat-card class="mx-auto mat-elevation-z10">
        <div class="my-auto">
          <div class="mb-4 text-center">
            <img
              class="mb-4"
              src="./../../../assets/img/oneops.png"
              width="250px"
            />
          </div>
          <div class="text-center">
            <button mat-flat-button id="googleBtn" color="accent" class="latam_bold">
              SIGN IN
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="login-background">
    <div class="row h-100">
      <div class="col-sm-8 d-none d-sm-block bg-img"></div>
      <div class="col-12 col-sm-4 bg-blue-img"></div>
    </div>
  </div>
</div>

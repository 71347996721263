import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlightExceptionRange } from 'src/app/models/maintainers/flight-exception-range';
import { MaintainersService } from 'src/app/services/maintainers.service';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import { SubFlota } from 'src/app/models/maintainers/flotas-mm';
import { Mapa } from 'src/app/models/maintainers/map';
import { UserInfo } from 'src/app/models/login/user-info';
import { CabinType } from 'src/app/models/maintainers/cabinType';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-flight-exception-range',
  templateUrl: './flight-exception-range.component.html',
  styleUrls: ['./flight-exception-range.component.scss']
})
export class FlightExceptionRangeComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['codigoLineaAerea','desde', 'hasta','flotasMm','mapa','configuration'];
  dataSource: MatTableDataSource<FlightExceptionRange>;
  subFlotas: SubFlota[] = [];
  mapas: Mapa[] = [];
  isAdmin:boolean = false;
  user: UserInfo;
  cabinTypes: CabinType[] = [];

  constructor(private constants: Constants,
    private service: MaintainersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    let roles:any = JSON.parse(sessionStorage.getItem('userInfo'));
    this.user = new UserInfo(roles.roles);
    this.isAdmin = this.user.isAdminUser();
    if (this.isAdmin){
      this.displayedColumns.push('action');
    }
    this.dataSource = new MatTableDataSource<any>([]);
    this.setFlightExceptionRange();
    this.setSubFlotas();
    this.setMapas();
    this.setCabinas();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  search(searchi:any): void {
    console.log(searchi);
    //let subFlota: SubFlota = {'idFlotaMm':225,'grupoFlota':'32F'};
    this.dataSource.filter = `${searchi}`;
    //this.dataSource.filter = `${subFlota}`;

  }

  openEdit(row: FlightExceptionRange) {
    console.log(JSON.stringify(row));
    let data: any;

    if (row) {
      data = { mode: 1, subFlotas: this.subFlotas, mapas: this.mapas, cabinTypes: this.cabinTypes, row }
    } else {
      let flotasMm: SubFlota = {idFlotaMm:null, grupoFlota:null};
      let tipoCabina:CabinType= {idCabina:null, descripcion:null}
      let mapa: Mapa = {idMapa:null,codigoMapa:null,descripcionMapa:null,tiposCabina:tipoCabina};
      row = { idRango: null, 	codigoLineaAerea:'', desde:null, hasta:null,
        flotasMm: flotasMm, mapa: mapa }

      data = { mode: 0, subFlotas: this.subFlotas, mapas: this.mapas, cabinTypes: this.cabinTypes,  row }
    }

    const dialogRef = this.dialog.open(FlightExceptionRangeDialogOverviewEdit, {
      width: '520px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined){
        return;
      }
      console.log(`The dialog was closed: ${JSON.stringify(result.row)}`);
      result.row.mapa.tiposCabina.idCabina = result.row.mapa.tiposCabina.idCabina.idCabina;
      if(result.mode==0){
        this.service.saveFlightExceptionRange(result.row).subscribe((response: FlightExceptionRange) => {
          console.log(response);
          if(response){
            this.setFlightExceptionRange();
            SweetAlertUtils.show('Datos Guardados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')
          }
        },
          (err) => {
            console.log('Error creando.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );

      }else if(result.mode==1){
        this.service.updateFlightExceptionRange(data.row).subscribe((response: FlightExceptionRange) => {
          console.log(response);
          if(response){
            this.setFlightExceptionRange();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')
          }
        },
          (err) => {
            console.log('Error Actualizando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }

    });

  }

  delete(row: FlightExceptionRange): void {
    console.log(row);
    SweetAlertUtils.confirm(`¿Seguro que quieres eliminar el registro : ${row.codigoLineaAerea}
    - ${row.desde} - ${row.hasta} - ${row.flotasMm.grupoFlota} -  ${row.mapa.codigoMapa} - ${row.mapa.descripcionMapa}`)
    .then((result) => {
      if (result.value) {
        console.log('reprocess');
        this.service.deleteFlightExceptionRange(row).subscribe((response: any) => {
          console.log(response);
          if(response){
            this.setFlightExceptionRange();
            SweetAlertUtils.show('Registro Eliminado','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')
          }
        },
          (err) => {
            console.log('Error Eliminando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }
    });

  }

  setFlightExceptionRange() {

    this.service.findFlightExceptionRange("").subscribe((data: FlightExceptionRange[]) => {
      //console.log(JSON.stringify(data));
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },
      (err) => {
        console.log('Error recuperando información de rango de por vuelos.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
      }
    );
  }

  setSubFlotas() {
      this.service.findFlotasMm("").subscribe((response: SubFlota[]) => {
        console.log(response);
        this.subFlotas = response;
      },
        (err) => {
          console.log('Error recuperando información de flotas-mm.', err);
          if (err.status == 400) {
            console.log("data not found.");
          } else {
            SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
          }
        }
      );
  }

  setMapas() {
    this.service.findMapas("").subscribe((response: Mapa[]) => {
      console.log(response);
      this.mapas = response;
    },
      (err) => {
        console.log('Error recuperando información de mapas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
}

setCabinas() {
  this.service.findCabinTypes("").subscribe((response: CabinType[]) => {
    console.log(response);
    this.cabinTypes = response;
  },
    (err) => {
      console.log('Error recuperando información de cabinas.', err);
      if (err.status == 400) {
        console.log("data not found.");
      } else {
        SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
      }
    }
  );
}



}

@Component({
  selector: 'dialog-overview-edit',
  templateUrl: 'dialog-overview-edit.html'
})
export class FlightExceptionRangeDialogOverviewEdit implements OnInit, AfterViewInit {
  @ViewChild('matSelectMapa', { static: true }) matSelectMapa: MatSelect;
  protected _onDestroy = new Subject<void>();
  public mapaCtrl: FormControl = new FormControl();
  public mapaFilterCtrl: FormControl = new FormControl();
  public filteredMapas: ReplaySubject<Mapa[]> = new ReplaySubject<Mapa[]>(1);
  modeDescription: string;

  mapas: Mapa[] = [];
  mapasFilter: Mapa[] = [];

  constructor(
    public dialogRef: MatDialogRef<FlightExceptionRangeDialogOverviewEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(`mode: ${data.mode}`);
    this.modeDescription = (data.mode == 0) ? 'Nueva' : 'Editar';
    this.mapas = this.data.mapas;
    this.mapasFilter = this.mapas;
  }

  ngOnInit() {
    this.filteredMapas.next(this.mapasFilter.slice());

    this.mapaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMapas();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredMapas
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if(this.matSelectMapa){
          this.matSelectMapa.compareWith = (a: Mapa, b: Mapa) => a && b && a.idMapa === b.idMapa;
        }
      });
  }

  protected filterMapas() {
    if (!this.mapasFilter) {
      return;
    }

    let search = this.mapaFilterCtrl.value;
    if (!search) {
      this.filteredMapas.next(this.mapasFilter.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMapas.next(
      this.mapasFilter.filter(mapa => mapa.descripcionMapa.toLowerCase().indexOf(search) > -1 || mapa.codigoMapa.toString().indexOf(search) > -1)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(myForm: NgForm) { }

  converString(text: string) {
    return text + '';
  }

  selectCabin(cabinTypeId: number) {
    this.filteredMapas = new ReplaySubject<Mapa[]>(1);
    console.log('cabinType: ' + cabinTypeId);
    console.log('mapas: ' + this.mapas.length);
    console.log('mapasFilter: ' + this.mapasFilter.length);
    this.mapasFilter = this.mapas;
    this.mapasFilter = this.mapasFilter.filter(mapa => mapa.tiposCabina.idCabina === cabinTypeId);
    console.log('mapasFilter: ' + this.mapasFilter.length);
    this.filteredMapas.next(this.mapasFilter.slice());
  }
}
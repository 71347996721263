import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from '../../common/constants';
import { JsonExporterService } from 'mat-table-exporter';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrackingCommandService } from '../../services/tracking-command.service';
import { ChangeFilterTrackCommandRequest } from '../../models/tracking-command/track-command-request';
import { TrackCommandResponse } from '../../models/tracking-command/track-command-response';
import { SweetAlertUtils } from '../../utils/SweetAlertUtils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tracking-command',
  templateUrl: './tracking-command.component.html',
  styleUrls: ['./tracking-command.component.scss']
})
export class TrackingCommandComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  element: HTMLElement;
  formGroup: FormGroup;
  displayedColumns: string[] = ['smFlightDateUtc', 'smCarrier', 'smFlightNumber', 'smEventType', 'smEventSubtype',
    'smTimestampStart', 'smMessageIn', 'status', 'detalleComando', 'actions'];
  dataSource: MatTableDataSource<TrackCommandResponse>;
  request: ChangeFilterTrackCommandRequest;

  starDate: Date;
  endDate: Date;
  minDateInit: Date;
  minDateEnd: Date;
  maxDateEnd: Date;
  loading = false;
  types: string[] = this.constants.TYPE;
  subtypes: string[] = [];

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  constructor(
    private constants: Constants,
    private service: TrackingCommandService,
    private expService: JsonExporterService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<TrackCommandResponse>([]);
    this.request = new ChangeFilterTrackCommandRequest();
    this.loadSubTypes()
    let dated: Date = new Date();
    this.starDate = new Date();
    this.endDate = new Date();
    this.starDate.setHours(dated.getHours() - 24);
    this.endDate.setHours(dated.getHours() + 72);
    this.request.fromDate = this.starDate.toISOString().slice(0, 10);
    this.request.toDate = this.endDate.toISOString().slice(0, 10);
    this.request.subTypes = [];
    this.request.type = [];
    this.formGroup = new FormGroup({
      startDate: new FormControl(this.starDate, Validators.required),
      endDate: new FormControl(this.endDate, Validators.required),
      flightNumber: new FormControl(''),
      type: new FormControl(''),
      subtype: new FormControl('')
    });
    this.setCommandResult(this.request);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onDateEndChange(event): void {
    this.minDateInit = new Date(event.value);
    this.minDateInit.setHours(this.minDateInit.getHours() - 96);
  }

  onDateInitChange(event): void {
    this.minDateEnd = event.value;
    this.maxDateEnd = new Date(event.value);
    this.maxDateEnd.setHours(this.maxDateEnd.getHours() + 96);
  }

  search(): void {
    console.log('search');
    this.spinnerService.show();

    if (!this.formGroup.valid) {
      this.spinnerService.hide();
      return;
    }

    this.request.fromDate = this.formGroup.controls.startDate.value.toISOString().slice(0, 10);
    this.request.toDate = this.formGroup.controls.endDate.value.toISOString().slice(0, 10);
    this.request.flightNumber = this.formGroup.controls.flightNumber.value;
    this.request.type = this.validListValue(this.formGroup.controls.type.value);
    this.request.subTypes = this.validListValue(this.formGroup.controls.subtype.value);
    this.setCommandResult(this.request);
  }

  viewCommand(row: TrackCommandResponse): void {
    console.log(JSON.stringify(row));
    let data: any;
    data = { siccoLogMessages: row.siccoLogMessages, siccoErrorDef: row.siccoErrorDef }


    const dialogRef = this.dialog.open(TrackingCommandDialogOverview, {
      width: '700px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`The dialog was closed`);
    });


  }

  selectType(listType: string[]) {
    console.log(listType);

    if (listType.some(type => type === 'MVT')) {
      this.subtypes = [];
      this.subtypes.push(...this.constants.SUBTYPE_MAP.get('MVT'));
    }

    if (listType.some(type => type === 'ASM')) {
      this.subtypes = [];
      this.subtypes.push(...this.constants.SUBTYPE_MAP.get('ASM'));
      if (listType.some(type => type === 'MVT')) {
        this.subtypes.push(...this.constants.SUBTYPE_MAP.get('MVT'));
      }
    }

    if (listType.some(type => type === 'type') || listType.length == 0) {
      this.subtypes = [];
      this.loadSubTypes();
    }

  }

  loadSubTypes() {
    this.subtypes.push(...this.constants.SUBTYPE_MAP.get('ASM'));
    this.subtypes.push(...this.constants.SUBTYPE_MAP.get('MVT'));
    console.log(this.subtypes);
  }

  validListValue(value: any) {
    if (value) {
      return value;
    } else {
      return [];
    }
  }

  toggleAllSelection(e): void {
    switch (e.value) {
      case 'type':
        if (e._selected) {
          this.formGroup.controls.type.setValue(this.types);
          e._selected = true;
        } else {
          this.formGroup.controls.type.setValue([]);
        }
        break;
      case 'subtype':
        if (e._selected) {
          this.formGroup.controls.subtype.setValue(this.subtypes);
          e._selected = true;
        } else {
          this.formGroup.controls.subtype.setValue([]);
        }
        break;
    }

  }

  verifyAll(value: string, e): void {
    switch (value) {
      case 'type':
        if (this.formGroup.controls.type.value.length !== this.types.length) {
          e._selected = false;
        } else {
          this.element = document.getElementById('typeOption') as HTMLElement;
          this.element.click();
        }
        break;

      case 'subtype':
        if (this.formGroup.controls.subtype.value.length !== this.subtypes.length) {
          e._selected = false;
        } else {
          this.element = document.getElementById('subtypeOption') as HTMLElement;
          this.element.click();
        }
        break;
    }
  }

  setCommandResult(request: ChangeFilterTrackCommandRequest): void {
    this.spinnerService.show();
    this.service.findListCommandResultFilter(request).subscribe((response: TrackCommandResponse[]) => {
      //console.log(response);
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinnerService.hide();
    },
      (err) => {
        this.spinnerService.hide();
        console.log('Error recuperando información de Tracking Command.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
      }
    );
  }

  reprocess(row?: TrackCommandResponse) {
    SweetAlertUtils.confirm(`¿Seguro que quieres reprocesar el vuelo: ${row.smCarrier + row.smFlightNumber}
    con mensaje entrada: ${row.smMessageIn}`).then((result) => {
      if (result.value) {
        console.log(`reprocess ${JSON.stringify(row)}`);
        this.spinnerService.show();

        let command: any = {
          smId: row.smId,
          smTrackid: row.smTrackid,
          smEventType: row.smEventType
        };

        this.service.reprocessCommand(command).subscribe((data: any) => {
          console.log('result: ' + JSON.stringify(data));
          if (data) {
            if (data.status == "OK") {
              SweetAlertUtils.show(`Vuelo: ${row.smCarrier + row.smFlightNumber} reprocesado.`, 'success', 'Resultado Reprocesar')
            } else {
              SweetAlertUtils.show(data.details, 'warning', 'Resultado Reprocesar')
            }
          } else {
            SweetAlertUtils.show('No se pudo reprocesar el comando.', 'warning', 'Resultado Reprocesar')
          }

        },
          (err) => {
            console.log(`Error reprocess command.`, err);
            this.spinnerService.hide();
          },
          () => {
            console.log('complete');
            this.spinnerService.hide();
          });

      }
    });
  }

}

@Component({
  selector: 'dialog-overview',
  templateUrl: 'dialog-overview.html'
})
export class TrackingCommandDialogOverview implements OnInit, AfterViewInit {
  modeDescription: string;
  label1: string;
  label2: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['field1'];
  dataSource: MatTableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<TrackingCommandDialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>([]);


    if (this.data.siccoLogMessages) {
      console.log('siccoLogMessages: ' + this.data.siccoLogMessages.length)
      if (this.data.siccoLogMessages.length > 0) {
        this.label1 = 'Comando';
        this.label2 = 'Resultado';
        this.dataSource = new MatTableDataSource(this.data.siccoLogMessages);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (this.data.siccoErrorDef) {
        console.log('siccoErrorDef: ' + JSON.stringify(this.data.siccoErrorDef))
        this.label1 = 'Code -';
        this.label2 = '> Error';
        this.displayedColumns.push('field2');
        /*let siccoErrorDef = this.data.siccoErrorDef.map( item => { 
          return { slCommand: item.seErrorCode , slCommandResult : item.seErrorDescription }; 
        });*/
        let siccoErrorDef = {
          slCommand: this.data.siccoErrorDef.seErrorCode,
          slCommandResult: this.data.siccoErrorDef.seErrorDescription
        }
        let listErrorDef: any[] = [];
        listErrorDef.push(siccoErrorDef);
        this.dataSource = new MatTableDataSource(listErrorDef);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
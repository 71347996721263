<style>
  :host {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Inhibición por vuelo</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" novalidate>

    <mat-form-field appearance="fill">
      <mat-label>Número de Vuelo</mat-label>
      <input matInput [(ngModel)]="data.row.flightNumber" name="flightNumber" required
       type="number" maxlength="4" min="0"> 
    </mat-form-field>
  
    <mat-form-field appearance="fill">
      <mat-label>Origen</mat-label>
      <input matInput [(ngModel)]="data.row.dprtrAirport" name="dprtrAirport" required
       maxlength="3"  (ngModelChange)="data.row.dprtrAirport = $event.toUpperCase()">
    </mat-form-field>  

    <mat-form-field appearance="fill">
      <mat-label>Destino</mat-label>
      <input matInput [(ngModel)]="data.row.arrvlAirport" name="arrvlAirport"  required
       maxlength="3" (ngModelChange)="data.row.arrvlAirport = $event.toUpperCase()"> 
    </mat-form-field>

  </form>
 
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data"  [disabled]="!myForm.valid"><!--cdkFocusInitial-->
        Guardar</button>
    </div>
  </div>
  
  
</div>

<style>
  :host {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  mat-paginator-container {
    justify-content: flex-start;
  }
  
</style>

<h1 style="color:#1b0088;" mat-dialog-title>Detalle de Comando</h1>
<div mat-dialog-content>

  <table mat-table [dataSource]="dataSource"  >

  <ng-container matColumnDef="field1">
      <th mat-header-cell *matHeaderCellDef  > {{label1}} </th>
      <td mat-cell *matCellDef="let element"> {{element.slCommand}}&nbsp; 
        <mat-icon [matTooltip]="element.slCommandResult">info</mat-icon></td>
  </ng-container>

  <ng-container matColumnDef="field2">
      <th mat-header-cell *matHeaderCellDef  > {{label2}} </th>
      <td mat-cell *matCellDef="let element"> {{element.slCommandResult}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator style="width: 660px;" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


</div>
<div mat-dialog-actions>
  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between" style="width: 470px;">
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
  </div>
</div>
<style>
    :host {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    mat-form-field {
        width: 200px;
        /*display: unset;*/
    }

    hr {
        height: 20px;
    }
</style>

<h1 style="color:#1b0088;" mat-dialog-title>Añadir Flota o Sub Flota</h1>
<div mat-dialog-content>
    <!--div class="mb-3"-->
    <mat-form-field appearance="fill">
        <mat-label>Crear Flota</mat-label>
        <input matInput [(ngModel)]="data.flota" (ngModelChange)="data.flota = $event.toString().toUpperCase()" maxlength="5">
    </mat-form-field>
    <!--/div-->
    <hr>
    <!--div class="mb-3"-->
    <mat-form-field appearance="fill">
        <mat-label>Crear SubFlota</mat-label>
        <input matInput [(ngModel)]="data.subFlota" (ngModelChange)="data.subFlota = $event.toString().toUpperCase()" maxlength="5">
    </mat-form-field>
    <!--/div-->
</div>
<div mat-dialog-actions class="d-flex justify-content-between" >
    <div class="d-flex flex-row bd-highlight mb-3" >
        <div class="p-2 bd-highlight">
            <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
        </div>
        <div class="p-2 bd-highlight">
            &nbsp;&nbsp;&nbsp;
        </div>
        <div class="p-2 bd-highlight">
            <button mat-raised-button color="warn" [mat-dialog-close]="data" cdkFocusInitial>Guardar</button>
        </div>
    </div>


</div>
import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AircraRegistration} from 'src/app/models/configuration-change/aircraft-registration';
import { MaintainersService } from 'src/app/services/maintainers.service';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import { Flota } from 'src/app/models/maintainers/flotas-host';
import { SubFlota } from 'src/app/models/maintainers/flotas-mm';
import { UserInfo } from 'src/app/models/login/user-info';

export interface DialogMatricula {
  idMatricula: string;
  matricula: string;
  flota: Flota;
  subflota: SubFlota;
}

export interface DialogFlotas {
  flota: string;
  subFlota: string;
}

export interface Matricula {
  letter?: string;
  matriculas: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-matriculas',
  templateUrl: './matriculas.component.html',
  styleUrls: ['./matriculas.component.scss']
})
export class MatriculasComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  formGroup: FormGroup = new FormGroup({
    matricula: new FormControl("", Validators.required)
  });
  displayedColumns: string[] = ['matricula', 'flota', 'subflota'];
  dataSource: MatTableDataSource<any>;

  matriculasOptions: Observable<Matricula[]>;
  matriculas: Matricula[] = [/*{
  letter: "Escribe una matricula",
  matriculas: ['Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota']
}*/];
  aircraftRegistration: AircraRegistration[];

  matricula: string = '';
  flotas: Flota[] = [];
  subFlotas: SubFlota[] = [];
  isAdmin:boolean = false;
  user: UserInfo;

  constructor(private constants: Constants,
    private _formBuilder: FormBuilder,
    private service: MaintainersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    let roles:any = JSON.parse(sessionStorage.getItem('userInfo'));
    this.user = new UserInfo(roles.roles);
    this.isAdmin = this.user.isAdminUser();
    if (this.isAdmin){
      this.displayedColumns.push('action');
    }  
    this.dataSource = new MatTableDataSource<AircraRegistration>([]);
    this.setMatriculas();
    this.setFlotas();
    this.setSubFlotas();    
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  setMatriculas() {

    this.service.findMatriculas("").subscribe((data: AircraRegistration[]) => {
      this.aircraftRegistration = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      let matriculas: string[] = [];
      data.forEach(row => { matriculas.push(row.matricula) });
      this.matriculas =
        [{
          letter: "Escribe una matricula",
          matriculas
        }];

      this.formGroup = this._formBuilder.group({
        matricula: new FormControl("", Validators.required)
      });

      this.matriculasOptions = this.formGroup.get('matricula')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterGroup(value)));
    },
      (err) => {
        console.log('Error recuperando información de matriculas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.CONFIGURATION_PROCESS_ERROR);
        }
      }
    );
  }

  setFlotas() {
    this.service.findFlotasHost("").subscribe((response: Flota[]) => {
      console.log(response);
      this.flotas = response;
    },
      (err) => {
        console.log('Error recuperando información de flotas-host.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }


  setSubFlotas() {
    this.service.findFlotasMm("").subscribe((response: SubFlota[]) => {
      console.log(response);
      this.subFlotas = response;
    },
      (err) => {
        console.log('Error recuperando información de flotas-mm.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }

  private _filterGroup(value: string): Matricula[] {
    if (value) {
      return this.matriculas
        .map(group => ({ letter: group.letter, matriculas: _filter(group.matriculas, value) }))
        .filter(group => group.matriculas.length > 0);
    }

    return this.matriculas;
  }

  openEdit(row: AircraRegistration) {

    console.log(JSON.stringify(row));
    let data: any;

    if (row) {
      data = {
        mode: 1, row, flotas: this.flotas, subFlotas: this.subFlotas
      }
    } else {
      let flota: Flota = {idFlotaHost:null,grupoFlota:null}
      let subFlota: SubFlota = {idFlotaMm:null,grupoFlota:null}
      row = {idMatricula:null, matricula:null , flotasHost:flota, flotasMm: subFlota}
      data = {
        mode: 0, row, flotas: this.flotas, subFlotas: this.subFlotas
      }
    }

    const dialogRef = this.dialog.open(MatriculaDialogOverviewEdit, {
      width: '260px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined){
        return;
      }
      //let flota:Flota = this.flotas.filter(flota =>flota.idFlotaMm ==result.idFlota)[0];
      //let subflota:SubFlota = this.subFlotas.filter(subflota =>subflota.idFlotaHost ==result.idSubFlota)[0];


      /*if (flota == null || subflota == null){
        console.log('Error: flota o subflota no encontrada')
        console.log(flota);
        console.log(subflota);
        SweetAlertUtils.show('No se pudo realizar el cambio','error','Error');  
        return;
      }

      let aircraft : AircraRegistration = {
        idMatricula: result.idMatricula,
        matricula: result.matricula,
        flotasMm: flota,
        flotasHost: subflota,
      }*/
      console.log(`The dialog was closed: ${JSON.stringify(result.row)}`);

      if(result.mode==0){
        this.service.saveMatriculas(result.row).subscribe((response: AircraRegistration) => {
          console.log(response);
          if(response){
            this.setMatriculas();
            SweetAlertUtils.show('Datos Guardados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error creando matriculas.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );

      }else if(result.mode==1){
        this.service.updateMatriculas(result.row).subscribe((response: AircraRegistration) => {
          console.log(response);
          if(response){
            this.setMatriculas();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error Actualizando matriculas', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }


    });
  }

  search(searchi: any): void {
    console.log(searchi);
    this.dataSource.filter = `${searchi}`;
  }
  
  addFloat(): void {
    const dialogRef = this.dialog.open(MatriculaDialogOverviewFlota, {
      width: '250x',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined){
        return;
      }
      console.log(`The dialog was closed ${JSON.stringify(result)}`);
      //LLAMADA POST FLOTA
      if (result.flota){
        let flota: Flota = { idFlotaHost:null, grupoFlota:result.flota}
  
        this.service.saveFlotasHost(flota).subscribe((response: Flota) => {
          console.log(response);
          if(response == null){
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }else if(response.idFlotaHost){
            this.setFlotas();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }
        },
          (err) => {
            console.log('Error creando flotas-host.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );   
      }

      //LLAMADA POST SUB FLOTA
      if (result.subFlota){
        let subFlota: SubFlota = { idFlotaMm:null, grupoFlota:result.subFlota}
  
        this.service.saveFlotasMm(subFlota).subscribe((response: SubFlota) => {
          console.log(response);
          if(response == null){
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }else if(response.idFlotaMm){
            this.setSubFlotas();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }
        },
          (err) => {
            console.log('Error creando flotas-mm.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );      

      }

    });
  }

}

@Component({
  selector: 'dialog-overview-edit',
  templateUrl: 'dialog-overview-edit.html'
})
export class MatriculaDialogOverviewEdit {
  modeDescription: string;

  /*formGroup: FormGroup = new FormGroup({
    matricula: new FormControl("", Validators.required),
    idFlota: new FormControl("", Validators.required),
    idSubFlota: new FormControl("", Validators.required)
  });*/

  constructor(
    public dialogRef: MatDialogRef<MatriculaDialogOverviewEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(`mode: ${data.mode}`);
    this.modeDescription = (data.mode == 0) ? 'Nueva' : 'Editar';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(myForm: NgForm) {  }

}

@Component({
  selector: 'dialog-overview-flota',
  templateUrl: 'dialog-overview-flota.html',
})
export class MatriculaDialogOverviewFlota {

  constructor(
    public dialogRef: MatDialogRef<MatriculaDialogOverviewFlota>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFlotas) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

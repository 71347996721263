<div class="container-fluid m-3">

    <div class="row mt-4">
        <div class="col fields">
            <mat-card class="mat-elevation-z3" style="width: 93.3%;">
                <mat-card-title>SFTP
                    <span class="right">
                        <button mat-icon-button color="primary" aria-label="Limpiar" (click)="clean()">
                            <mat-label class="clean">Limpiar Campos </mat-label><mat-icon>backspace</mat-icon>                        
                        </button>
                    </span>
                </mat-card-title>
                <mat-card-subtitle>Seleccione una ruta para realizar la búsqueda</mat-card-subtitle>
                <mat-card-content>
                    <form [formGroup]="sftpForm">
                        <div class="row">
                            <div class="col-10 font-weight-bold">
                                <mat-form-field appearance="legacy" style="width: 100%">
                                    <mat-label>Ruta Seleccionada</mat-label>
                                    <input matInput type="text" formControlName="pathCtrl" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right action">
                                <div class="search">
                                    <button mat-flat-button color="accent" [disabled]="!sftpForm.controls.pathCtrl.value" (click)="search()">
                                        <span *ngIf="false" id="download-icon" class="material-icons">refresh</span>
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-4">
            <mat-card class="mat-elevation-z3" style="height: 100%;">
                <mat-card-content class="m-2">  
                    <app-sftp-aircom-sita-tree #treeComponent id="treeComponent" [directories]="directories" 
                        (selectedPath)="getSelectedPath($event)" (refresh)="getDirectories($event)"></app-sftp-aircom-sita-tree>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-8 pl-0">
            <mat-card class="mat-elevation-z3" style="height: 100%;">
                <mat-card-content>
                    <div class="row mt-4">
                        <div class="col-4">
                            <mat-label>{{this.selection.selected.length}} Seleccionado(s)</mat-label>
                        </div>
                        <div class="col-4"></div>
                        <!-- <div class="col-3"></div> -->
                        <div class="col-4" style="margin-top: -1%;">
                            <button mat-icon-button color="primary"
                                (click)="download()"
                                aria-label="Example icon button with a home icon">
                                <mat-icon >download</mat-icon>
                                <mat-label >Descargar Archivos</mat-label>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput [(ngModel)]="globalFilter" (ngModelChange)="applyFilter($event)" placeholder="Filtrar Nombre Archivo">
                            </mat-form-field>
                        </div> 
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label style="font-weight: bold;"><h5 *ngIf="selectedPath">Ruta actual: {{selectedPath}}</h5></mat-label>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <mat-card class="p-0 mat-elevation-z3" style="width: 100%;">
                            <mat-card-content>
                                <table mat-table [dataSource]="dataSource" matSort cdkDropList
                                    cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matTableExporter
                                    #exporter="matTableExporter" [hiddenColumns]="[10]" (exportStarted)="hiddenElements()"
                                    (exportCompleted)="showElements()">
            
                                    <ng-container matColumnDef="select" style="width: 30%;">
                                        <th mat-header-cell *matHeaderCellDef cdkDrag>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
            
                                    <!-- <ng-container matColumnDef="path" style="width: 60%;">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Path </th>
                                        <td mat-cell *matCellDef="let element"> {{element.path}} </td>
                                    </ng-container> -->
            
                                    <ng-container matColumnDef="fileName" style="width: 30%;">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Nombre Archivo </th>
                                        <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                                    </ng-container>
            
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                        
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">Loading... </p>
</ngx-spinner>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewerSftpGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userInfoVar = sessionStorage.getItem('userInfo');
    const userInfo = userInfoVar ? JSON.parse(userInfoVar) : null
    return userInfo && userInfo.roles &&
      userInfo.roles.some(rol => rol === 'ADMIN') || userInfo.roles.some(rol => rol === 'ADMIN_SFTP') || userInfo.roles.some(rol => rol === 'VIEWER_SFTP');
  }

}

<div class="container-fluid m-3">
  <div class="filters">
    <mat-card class="mat-elevation-z3">
      <mat-card-title>Tracking Command</mat-card-title>
      <mat-card-subtitle>Selecciona y modifica los datos según requerimiento</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="formGroup">

          <div class="fields row g-3">


            <div class="col-md-2">
              <mat-form-field class="mr-3">
                <mat-label>Start Date</mat-label>
                <input matInput formControlName="startDate" [matDatepicker]="startDate" [min]="minDateInit"
                  (click)="startDate.open()" (dateChange)="onDateInitChange($event)" readonly />
                <mat-datepicker-toggle matSuffix [for]="startDate">
                </mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="mr-3">
                <mat-label>End Date</mat-label>
                <input matInput formControlName="endDate" [matDatepicker]="endDate" [min]="minDateEnd"
                  [max]="maxDateEnd" (click)="endDate.open()" (dateChange)="onDateEndChange($event)" readonly />
                <mat-datepicker-toggle matSuffix [for]="endDate">
                </mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="mr-3">
                <mat-label>Vuelo</mat-label>
                <input matInput formControlName="flightNumber" placeholder="Escribe el Vuelo" 
                type="number" min="0" max="99999">
              </mat-form-field>

            </div>
            <div class="col-md-2">
              <mat-form-field class="mr-3 pb-lg-2">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type" multiple (selectionChange)="selectType($event.value)">
                  <ng-container *ngIf="types">
                    <mat-option #typesAll id="typeOption" (click)="toggleAllSelection(typesAll)" value="type">
                      ALL</mat-option>
                    <mat-option *ngFor="let type of types" [value]="type" (click)="verifyAll('type', typesAll)">{{type}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="mr-3 pb-lg-2">
                <mat-label>SubTipo</mat-label>
                <mat-select formControlName="subtype" multiple>
                  <ng-container *ngIf="subtypes">
                    <mat-option #subtypesAll id="subtypeOption" (click)="toggleAllSelection(subtypesAll)"
                      value="subtype">
                      ALL</mat-option>
                    <mat-option *ngFor="let subtype of subtypes" [value]="subtype"
                      (click)="verifyAll('subtype', subtypesAll)">{{subtype}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 buttons">
              <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                <span *ngIf="false" id="download-icon" class="material-icons">refresh</span>
                Buscar
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="grid row mt-4">
    <mat-card class="mat-elevation-z3 scroll">
      <mat-card-content class="p-2">
        <div class="body">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="drop($event)" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[10]">
              <ng-container matColumnDef="smEventType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.smEventType}} </td>
              </ng-container>
              <ng-container matColumnDef="smEventSubtype">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Sub Tipo </th>
                <td mat-cell *matCellDef="let element">
                  {{element.smEventSubtype}} </td>
              </ng-container>
              <ng-container matColumnDef="smCarrier">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Carrier </th>
                <td mat-cell *matCellDef="let element"> {{element.smCarrier}} </td>
              </ng-container>
              <ng-container matColumnDef="smFlightDateUtc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha UTC </th>
                <td mat-cell *matCellDef="let element"> {{element.smFlightDateUtc}} </td>
              </ng-container>
              <ng-container matColumnDef="smFlightNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Numero Vuelo </th>
                <td mat-cell *matCellDef="let element"> {{element.smFlightNumber}} </td>
              </ng-container>
              <ng-container matColumnDef="smMessageIn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Mensaje IN </th>
                <td mat-cell *matCellDef="let element"> {{element.smMessageIn}} </td>
              </ng-container>
              <ng-container matColumnDef="smTimestampStart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                  ejecución </th>
                <td mat-cell *matCellDef="let element"> {{element.smTimestampStart}} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Estatus </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
              </ng-container>
<!--               <ng-container matColumnDef="exectimeMilisecond">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Tiempo ejecucion </th>
                <td mat-cell *matCellDef="let element"> {{element.exectimeMilisecond}} </td>
              </ng-container> -->
              <ng-container matColumnDef="detalleComando">
                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Detalle Comando</th>
                <td mat-cell *matCellDef="let element">
                  <div class="format-actions">
                    <div class="example-button-container">
                      <button mat-stroked-button color="primary" (click)="viewCommand(element)"
                        [disabled]="(element.siccoLogMessages.length==0 && !element.siccoErrorDef)">
                        Ver
                      </button>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Accion </th>
                <td mat-cell *matCellDef="let element">
                    <div class="format-actions">
                        <div class="example-button-container">
                            <button mat-mini-fab (click)="reprocess(element)" color="warn" [disabled]="false"
                                aria-label="Reprocesar">
                                <mat-icon matTooltip="Reprocesar">replay</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
  <p style="color: white">Loading... </p>
</ngx-spinner>
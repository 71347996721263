import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AircraRegistration } from '../models/configuration-change/aircraft-registration';
import { ConfigurationChange } from 'src/app/models/configuration-change/configuration-request';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationChangeService {

  constructor(
    private constants: Constants,
    private http: HttpClient
  ) { }

  findExecute(request: any): Observable<AircraRegistration[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.AIRCRAFT_REGISTRATION}`;
    const headers = this.addHeaders('GET');
    return this.http.get<AircraRegistration[]>(URL, {headers});
  }

  executeConfig(request: ConfigurationChange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.CONFIGURATION_EXECUTE}`;
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request , {headers});
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    return headers;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { RequestOptions } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Constants } from '../common/constants';
import { UserInfo } from '../models/login/user-info';
import { LoginService } from './login.service';
import { CookieService } from 'ngx-cookie-service';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  //opts: RequestOptions = new RequestOptions();
  isLoadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: Observable<boolean> = this.isLoadingSub.asObservable();
  public auth2: any;
  private userInfo: UserInfo;

  constructor(private http: HttpClient, private constants: Constants,
     private loginService: LoginService,
     private cookieService:CookieService) { }

  setUser(profile) {
    this.isLoadingSub.next(true);
    let name = profile.getName();
    name = name.split(' ');
    localStorage.setItem('userName', name[0]);
    localStorage.setItem('userID', profile.getEmail());
    localStorage.setItem('lastName', profile.getFamilyName());
    localStorage.setItem('profilePicture', profile.getImageUrl());
    this.getUserAuthenticated().subscribe(
      (data) => {
        this.userInfo = new UserInfo(data.roles);
        sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        this.isLoadingSub.next(false);
        this.loginService.setCurrentLoginState(true);
        if (environment.brasilEnable && (this.userInfo.isAdminUser() || this.userInfo.isViewerUser())) {
          window.location.href = '/alte-oper';
        }else if (environment.brasilEnable && (this.userInfo.isAdminSftpUser() || this.userInfo.isViewerSftpUser())) {
          window.location.href = '/sftp-download';
        }else if (environment.brasilEnable && this.userInfo.isUploadSftpUser()) {
          window.location.href = '/sftp-upload';
        }else {
          window.location.href = '/alte-oper';
        }
      },
      (err) => {
        this.isLoadingSub.next(false);
        Swal.fire({
          text: 'You don\'t have permission to access to this application',
          title: 'Error!',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#e63358',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
          heightAuto: false,
          allowOutsideClick: false,
        }).then((result) => {
          this.cookieService.delete('id_token');
          localStorage.clear();
          window.location.href = '/login';
        });
      }
    );
  }

  getUserInfoAsPromise(): Promise<UserInfo> {
    let promise: Promise<UserInfo>;

    if (this.userInfo) {
      promise = new Promise<UserInfo>(
        (resolve, reject) => {
          resolve(this.userInfo);
        }
      );
    }

    if (sessionStorage.getItem('userInfo')) {
      promise = new Promise<UserInfo>(
        (resolve, reject) => {
          this.userInfo = new UserInfo(JSON.parse(sessionStorage.getItem('userInfo')).roles);
          resolve(this.userInfo);
        }
      );
    } else {
      this.isLoadingSub.next(true);
      promise = new Promise<UserInfo>(
        (resolve, reject) => {
          this.getUserAuthenticated().subscribe((data) => {
            this.userInfo = new UserInfo(data.roles);
            sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo));
            this.loginService.setCurrentLoginState(true);
            this.isLoadingSub.next(false);
            resolve(this.userInfo);
          },
            (err) => {
              reject(err);
              this.isLoadingSub.next(false);
              this.loginService.setCurrentLoginState(false);
              sessionStorage.clear();
              this.cookieService.delete('id_token');
              localStorage.clear();
              window.location.href = '/login';
            });
        }
      );

    }
    return promise;
  }

  getUserAuthenticated(): Observable<UserInfo> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.LOGIN_API}`;
    return this.http.get<UserInfo>(URL, { headers });
  }
  logOut() {
    console.log('logout - this.auth2', this.auth2)
    if (this.auth2 === undefined) {
      console.log ('if this.auth2: ', this.auth2)
      console.log ('gapi: ', gapi)
      // gapi.load('auth2', () => {
      //   this.auth2 = gapi.auth2
      //     .init({
      //       client_id: environment.clientId,
      //       cookiepolicy: 'single_host_origin',
      //       hosted_domain: 'latam.com',
      //       scope:  'https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.spaces.create https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/chat.messages.create https://www.googleapis.com/auth/chat.delete',
      //       prompt: 'consent'
      //     })
      //     .then(() => {
            this.signOut();
      //     });
      // });
    } else {
      console.log('else')
      this.cookieService.delete('id_token');
      this.cookieService.delete('googleApp');
      this.signOut();


    }
  }

  private signOut() {
    this.auth2 = gapi.auth2.getAuthInstance();
    this.auth2.signOut();
    this.auth2.disconnect();
    this.loginService.setCurrentLoginState(false);
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.delete('id_token');
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    } else {
      document
        .getElementById('googleBtn')
        .addEventListener('click', () => {
          this.auth2.signIn();
        });
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})



export class ConfirmationDialogComponent {
  groupsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string, supportAreas: any[] }
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    const formControls = this.data.supportAreas.reduce((acc, area) => {
      acc[area.name] = this.fb.control(area.selected);
      return acc;
    }, {});
    this.groupsForm = this.fb.group(formControls,{ validators: this.atLeastOneCheckboxSelectedValidator });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onCreateClick(): void {
    this.dialogRef.close(this.groupsForm.value);
  }
  atLeastOneCheckboxSelectedValidator(group: FormGroup): {[key: string]: any} | null {
    const controls = group.controls;
    const isAtLeastOneChecked = Object.keys(controls).some(key => controls[key].value);
    
    return isAtLeastOneChecked ? null : { 'notChecked': true };
  }
}
import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../common/constants';

interface Request {
  fileContent: FileList | null;
}

@Injectable({
  providedIn: 'root'
})
export class SftpUploadService {

  constructor(@Inject(HttpClient) private http: HttpClient, private constants: Constants) { }

  private allowedFileExtensions = ['jpg','jpeg','png','gif','bmp','txt','pdf','doc','docx','rtf','xls','xlsx','csv','ppt','pptx','zip','tar','gz','json','xml','csv','ini','yaml']; // Agrega las extensiones permitidas

  public async sendFiles( request: Request): Promise<any> {

    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_UPLOAD_FILES_BY_PATH}`;
    const formData = new FormData();
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    formData.append( 'path','/AS_IN/Uplink/');

    for (let i = 0; i < request.fileContent.length; i++) {
      if(this.isAllowedFileExtension(request.fileContent.item(i).name)){
        formData.append( 'fileContent', request.fileContent.item(i));
      }else {
        console.warn(`el archivo ${request.fileContent.item(i).name} tiene una extencion invalida`);
        // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario.
      }
      
    }

    const result = await this.http
        .post<void>(URL, formData, {headers,responseType:'text' as 'json'}).toPromise();
    return result;
  }

  private isAllowedFileExtension(fileName: string): boolean {
    const allowedExtensions = this.allowedFileExtensions.map(ext => ext.toLowerCase());
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return fileExtension && allowedExtensions.includes(fileExtension);
  }
}
import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PocGeService {

  startTime;
  endTime;

  constructor(private constants: Constants,
    private http: HttpClient) { }

  getFilePocGe(request: any): Observable<any[]> {
    const URL = `${environment.apiUrl}${this.constants.GENERATE_FILE_POCGE}?startTime=${request.startTime}&endTime=${request.endTime}`;
    const headers = this.addHeaders('GET');
    return this.http.get<any[]>(URL, { headers, responseType: 'blob' as 'json'});
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');

    return headers;
  }
}

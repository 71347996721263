import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlterOperRequest } from '../models/alterations-operationals/alter-oper-request';
import { AlterOperResponse } from '../models/alterations-operationals/alter-oper-response';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private constants: Constants,
    private http: HttpClient
  ) { }

  createUsers() {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.CREATE_USERS}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, { headers });
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');

    return headers;
  }
}

import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SftpUploadService} from '../../services/sftp-upload.service';
import {Constants} from '../../common/constants';
//import {SnackbarComponent} from '../complements/snackbar/snackbar.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {SweetAlertUtils} from '../../utils/SweetAlertUtils';

@Component({
  selector: 'app-sftp-aircom-sita-upload',
  templateUrl: './sftp-aircom-sita-upload.component.html',
  styleUrls: ['./sftp-aircom-sita-upload.component.scss']
})
export class SftpAircomSitaUploadComponent implements OnInit {

  constructor(private sftpUploadService: SftpUploadService, private constants: Constants,
    //private snackBar: SnackbarComponent, 
    private spinnerService: NgxSpinnerService) { }

  uploadFiles: FileList;
  loading = false;

  uploadForm = new FormGroup({
    fileUpload: new FormControl('', Validators.required )
  });

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
  }

  selectFiles(event) {
    this.uploadFiles = event.target.files;
  }

  sendRequest(): void {
    this.loadingStart();

    this.sftpUploadService
        .sendFiles({
          fileContent: this.uploadFiles
        })
        .then(
            (data) => {
              SweetAlertUtils.show('File uploaded successfully.','success');
              this.loadingFinnish();
            },
            (error) => {
              SweetAlertUtils.showError(error, 'Unable to complete upload file.');
              this.loadingFinnish();
            }
        );
    
  }

  clearForm() {
    this.uploadForm.reset();
    this.uploadFiles = null;
  }

  loadingFinnish() {
    this.loading = false;
    this.clearForm();
    this.spinnerService.hide();
  }

  loadingStart() {
    this.loading = true;
    this.spinnerService.show();
  }

}

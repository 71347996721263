<div class="container-fluid m-3">

    <div class="filters">

        <mat-card class="mat-elevation-z3">
            <mat-card-title>Alteraciones Operacionales
                <span class="right">
                    <button mat-icon-button color="primary" aria-label="Limpiar" (click)="clean()">
                        <mat-label class="clean">Limpiar Campos </mat-label>
                        <mat-icon>backspace</mat-icon>
                    </button>
                </span>
            </mat-card-title>
            <mat-card-subtitle>Selecciona uno o mas campos para realizar la búsqueda</mat-card-subtitle>
            <mat-card-content>
                <form [formGroup]="alterOperForm">

                    <div class="fields">

                        <div class="fields row g-3">
                            <div class="col-md-3">
                                <mat-form-field class="mr-3">
                                    <mat-label>Origen</mat-label>
                                    <input matInput formControlName="origin" placeholder="Origen"
                                        (change)="toUpperCase('origin')">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3">
                                    <mat-label>Destino</mat-label>
                                    <input matInput formControlName="destination" placeholder="Destino"
                                        (change)="toUpperCase('destination')">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3 pb-lg-2">
                                    <mat-label>Tipo de mensaje</mat-label>
                                    <mat-select formControlName="typeMessage" multiple>
                                        <ng-container *ngIf="typeMessages">
                                            <mat-option #typeMessageAll id="typeMessageOption"
                                                (click)="toggleAllSelection(typeMessageAll)" value="type">
                                                ALL</mat-option>
                                            <mat-option *ngFor="let type of typeMessages" [value]="type"
                                                (click)="verifyAll('type', typeMessageAll)">{{type}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3">
                                    <mat-label>Vuelo</mat-label>
                                    <input matInput formControlName="flightNumber" placeholder="Escribe el Vuelo"
                                        type="number">
                                </mat-form-field>
                            </div>


                            <div class="col-md-3">
                                <mat-form-field class="mr-3">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput formControlName="startDate" [matDatepicker]="startDate"
                                        [min]="minDateInit" (click)="startDate.open()" 
                                        (dateChange)="onDateInitChange($event)" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="startDate" >
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startDate disabled="false" ></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3">
                                    <mat-label>End Date</mat-label>
                                    <input matInput formControlName="endDate" [matDatepicker]="endDate"
                                        [min]="minDateEnd" [max]="maxDateEnd" (click)="endDate.open()"
                                        (dateChange)="onDateEndChange($event)" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="endDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #endDate disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3 pb-lg-2">
                                    <mat-label>Tipo de cambio</mat-label>
                                    <mat-select formControlName="typeChange" multiple>
                                        <ng-container *ngIf="typeChanges">
                                            <mat-option #typeChangeAll id="typeChangeOption"
                                                (click)="toggleAllSelection(typeChangeAll)" value="typeC">
                                                ALL</mat-option>
                                            <mat-option *ngFor="let typeC of typeChanges" [value]="typeC"
                                                (click)="verifyAll('typeC', typeChangeAll)">{{typeC}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mr-3 pb-lg-2">
                                    <mat-label>Estado</mat-label>
                                    <mat-select formControlName="state" multiple>
                                        <ng-container *ngIf="status">
                                            <mat-option #statusAll id="statuOption"
                                                (click)="toggleAllSelection(statusAll)" value="statu">ALL
                                            </mat-option>
                                            <mat-option *ngFor="let statu of status" [value]="statu"
                                                (click)="verifyAll('statu', statusAll)">{{statu}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>


                        <div class="action">

                            <div class="search">
                                <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                                    <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>

    </div>

    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-content class="p-2">
                <div class="options d-flex bd-highlight justify-content-between mb-3">
                    <div class="me-auto p-2 bd-highlight">
                        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
                            <mat-label>{{this.selection.selected.length}} Seleccionado(s)</mat-label>
                        </button>
                    </div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">
                        <button mat-icon-button color="primary" (click)="save()"
                            aria-label="Example icon button with a home icon">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                    <div class="p-2 bd-highlight">
                        <button mat-icon-button color="primary"
                            (click)="exporter.exportTable('xlsx', {fileName:excelName, sheet: 'resultado', Props: {Author: 'Latam'}})"
                            aria-label="Example icon button with a home icon">
                            <mat-icon>download</mat-icon>
                            <mat-label>Descargar Todos</mat-label>
                        </button>
                    </div>
                    <div class="p-2 bd-highlight">&nbsp;</div>
                </div>

                <div class="body">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort cdkDropList
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matTableExporter
                            #exporter="matTableExporter" [hiddenColumns]="[10]" (exportStarted)="hiddenElements()"
                            (exportCompleted)="showElements()">

                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef cdkDrag>
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="typeMessage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Tipo </th>
                                <td mat-cell *matCellDef="let element"> {{element.typeMessage}} </td>
                            </ng-container>

                            <ng-container matColumnDef="carrierComercial">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Carrier </th>
                                <td mat-cell *matCellDef="let element"> {{element.carrierComercial}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flightNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Vuelo </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.carrierComercial}}{{element.flightNumber}}{{element.suffix}} </td>
                            </ng-container>

                            <ng-container matColumnDef="suffix">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Suffix </th>
                                <td mat-cell *matCellDef="let element"> {{element.suffix}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dprtrAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Origen </th>
                                <td mat-cell *matCellDef="let element"> {{element.dprtrAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="arrvlAirport">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Destino </th>
                                <td mat-cell *matCellDef="let element"> {{element.arrvlAirport}} </td>
                            </ng-container>

                            <ng-container matColumnDef="scheduledFlightDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    Programada<br />(UTC) </th>
                                <td mat-cell *matCellDef="let element"> {{element.scheduledFlightDate}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Estado </th>
                                <td mat-cell *matCellDef="let element">
                                    <p *ngIf="!showScreen">
                                        {{(element.status==0)?'Failed':''}}
                                        {{(element.status==1)?'Pending':''}}
                                        {{(element.status==2)?'Success':''}}
                                        {{(element.status==3)?'Exclude':''}}
                                    </p>
                                    <span *ngIf="showScreen">
                                        <mat-icon *ngIf="element.status == '0'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-nok' :  element.status == '0'}" matTooltip="Failed">
                                            cancel
                                        </mat-icon>
                                        <mat-icon *ngIf="element.status == '2'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-ok' : element.status == '2'}" matTooltip="Success">
                                            check_circle
                                        </mat-icon>
                                        <mat-icon *ngIf="element.status == '1'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-wrn' : element.status == '1'}" matTooltip="Pending">
                                            warning
                                        </mat-icon>
                                        <mat-icon *ngIf="element.status == '3'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-wrn' : element.status == '3'}" matTooltip="Exclude">
                                            warning
                                        </mat-icon>
                                    </span>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="observation">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Observation </th>
                                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
                            </ng-container>

                            <!--ng-container matColumnDef="detailObservation">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Detail </th>
                                <td mat-cell *matCellDef="let element"> {{element.detailObservation}} </td>
                            </ng-container-->

                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Comentario </th>
                                <td mat-cell *matCellDef="let element"> {{element.message}} </td>
                            </ng-container>


                            <ng-container matColumnDef="dtCreation">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> DtCreation </th>
                                <td mat-cell *matCellDef="let element"> {{element.dtCreation}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dtLstEdition">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    Modificación<br />(UTC) </th>
                                <td mat-cell *matCellDef="let element"> {{element.dtLstEdition}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flagManual">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Tipo de Cambio </th>
                                <td mat-cell *matCellDef="let element"> {{(element.flagManual)?'Manual':'Automatico'}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="format-actions">
                                        <!--div class="swal2-icon swal2-info swal2-icon-show" style="display: flex;"><div class="swal2-icon-content">i</div></div-->
                                        <div class="example-button-container">
                                            <button mat-mini-fab [disabled]="!element.detailObservation"
                                                (click)="viewInfo(element)" color="primary" aria-label="Mas Info">
                                                <mat-icon matTooltip="Información">info</mat-icon>
                                            </button>
                                        </div>
                                        <!--button [disabled]="!element.detailObservation" (click)="viewInfo(element)" mat-stroked-button>Mas info</button-->
                                        <div class="example-button-container">
                                            <button mat-mini-fab (click)="reprocess(element)" color="warn" [disabled]="!element.aircraftRegistration"
                                                aria-label="Reprocesar">
                                                <mat-icon matTooltip="Reprocesar">replay</mat-icon>
                                            </button>
                                        </div>
                                        <!--button (click)="reprocess(element)" mat-stroked-button color="primary">Reprocesar</button-->
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>


</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">{{ngxSpinner}} </p>
</ngx-spinner>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { environment } from '../environments/environment';
import { LoginService } from './services/login.service';
import { CookieService } from 'ngx-cookie-service';

declare const gapi: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'change-config-fe';

  isLogin = false;
  public auth2: any;

  constructor(public router: Router,
    private loginService: LoginService,
    private cookieService: CookieService) {
    //llamado antes de ngOnInit()
  }

  ngOnInit() {
    //llamado después del constructor y después del primer ngOnChanges()
    this.loginService.loginState
      .subscribe((loginState: boolean) => {
        this.isLogin = loginState;
      });

    if (this.cookieService.get('id_token')) {
      this.isLogin = true;
      this.refreshToken();

      interval(1000 * 1800).subscribe(x => {
        this.refreshToken();
      });
    }
  }

  refreshToken() {
    const that = this;
    if (this.auth2 === undefined) {
      gapi.load('auth2', () => {
        that.auth2 = gapi.auth2.init({
          client_id: environment.clientId,
          cookiepolicy: 'single_host_origin',
          hosted_domain: 'latam.com',
          prompt: 'consent',
          access_type: 'offline'
        }).then(() => {
          this.refresh();
        });
      });
    } else {
      this.refresh();
    }
  }

  refresh() {
    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
      this.cookieService.set('id_token', gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token);
      this.loginService.setCurrentLoginState(true);
    } else {
      this.loginService.setCurrentLoginState(false);
      this.cookieService.delete('id_token');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigateByUrl('login');
    }
  }

}

<div class="example-container h-100" [class.example-is-mobile]="mobileQuery.matches" *ngIf="shouldRun" autosize>

  <mat-toolbar color="primary" class="example-toolbar">
    <div class="logo text-left pr-2 pl-2">
      <img src="../assets/img/latam-animated.gif" alt="Logo Latam" />
    </div>
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h5 class="right example-app-name">{{userEmail}}</h5>
    <div class="right2">
      <button mat-icon-button (click)="logout()">
        <mat-icon matTooltip="Salir">logout</mat-icon>
      </button>
    </div>

  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">


    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" opened="true"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <mat-accordion>
          <span *ngFor="let item of menu">
            <div>&nbsp;</div>
            <span *ngIf="item.children && item.children.length > 0">

              <mat-expansion-panel style="box-shadow: none; margin:0px;">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <!-- Cabeceras del submenu -->
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <mat-icon style="vertical-align: bottom;">{{item.iconName}}</mat-icon>
                      {{item.displayName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-list-item routerLink="{{child.route}}">
                    <!-- Entradas de cada submenú  -->
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <!--mat-icon>{{child.iconName}}</mat-icon-->
                      <p [innerHTML]="child.displayName"></p>
                    </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>

            </span>
            <span *ngIf="!item.children || item.children.length === 0">
              <mat-expansion-panel hideToggle class="main-entry" routerLink="{{item.route}}">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <mat-icon style="vertical-align: bottom;">{{item.iconName}}</mat-icon>
                      {{item.displayName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </span>

          </span>

          <div>&nbsp;</div>
          <span>
            <mat-list-item (click)="logout()">
              <!-- Entradas principales -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon style="vertical-align: bottom;">logout</mat-icon>
                Salir
              </div>
            </mat-list-item>
          </span>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{'margin-outlet' :  !snav.opened}">
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>

import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Response } from '../models/response';
import { AlterOperResponse } from '../models/alterations-operationals/alter-oper-response';
import { AlterOperRequest, ChangeManual } from '../models/alterations-operationals/alter-oper-request';
import { ConfigurationChange } from '../models/configuration-change/configuration-request';

@Injectable({
  providedIn: 'root'
})
export class AlterationsOperationalsService {

  constructor(private constants: Constants,
    private http: HttpClient) { }

  findAlterationsOperationals(request: AlterOperRequest): Observable<AlterOperResponse> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.ALTERATIONS_OPERATIONALS}`;
    const headers = this.addHeaders('POST');
    console.log(JSON.stringify(request));
    return this.http.post<AlterOperResponse>(URL, request, {headers});

  }

  saveFlagManual(request: ChangeManual[]): Observable<Response> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.ALTERATIONS_OPERATIONALS_FLAG}`;
    const headers = this.addHeaders('POST');
    console.log(JSON.stringify(request));
    return this.http.post<Response>(URL, request, {headers});
  }

  executeConfig(request: ConfigurationChange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.CONFIGURATION_EXECUTE}`;
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request , {headers});
  }
  
  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');

    return headers;
  }

  /*
    //let params = this.getParams(request); 
    //return this.http.get<AlterOperResponse>(URL, { headers, params });
  getParams(request: AlterOperRequest): HttpParams {
    let params = new HttpParams();
    params = params.set('fromDate', request.fromDate).set('toDate', request.toDate)

    //if (request.types){
      params = params.set('types', request.types.toString());
    //}
    //if (request.departure){
      params = params.set('departure', request.departure);
    //}
    //if (request.arrival){
      params = params.set('arrival', request.arrival);
    //}
    //if (request.flightNumber){
      params = params.set('flightNumber', request.flightNumber);
    //}
    //if (request.changeManual){
      params = params.set('changeManual', request.changeManual.toString());
    //}
    //if (request.status){
      params = params.set('status', request.status.toString());
    //}
    
   
    return params;
  }*/
  
}


import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from "../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AlterationsOperationalsService } from "../../services/alterations-operationals.service"
import { AlterOperResponse } from '../../models/alterations-operationals/alter-oper-response';
import { AlterOperRequest, ChangeManual } from '../../models/alterations-operationals/alter-oper-request';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { JsonExporterService, TxtExporterService } from 'mat-table-exporter';
import { Response } from 'src/app/models/response';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationChange } from 'src/app/models/configuration-change/configuration-request';

@Component({
  selector: 'app-alterations-operationals',
  templateUrl: './alterations-operationals.component.html',
  styleUrls: ['./alterations-operationals.component.scss']
})
export class AlterationsOperationalsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['select' , 'typeMessage', 'scheduledFlightDate', /*'carrierComercial',*/ 'flightNumber', /*'suffix',*/
    'dprtrAirport', 'arrvlAirport', 'status', //'observation', 'detailObservation',
    'message', /*'dtCreation',*/ 'dtLstEdition', 'flagManual','actions'];
  dataSource: MatTableDataSource<AlterOperResponse>;
  selection = new SelectionModel<AlterOperResponse>(true, []);
  request: AlterOperRequest;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  element: HTMLElement;
  typeMessages: string[] = this.constants.TYPE_MESSAGE;
  typeChanges: string[] = this.constants.TYPE_CHANGE;
  status: string[] = this.constants.STATUS;
  loading: boolean = false;
  alterOperForm: FormGroup;

  showScreen:boolean = true;
  excelName: string;

  starDate: Date;
  endDate: Date;

  minDateInit: Date;
  minDateEnd: Date;
  maxDateEnd: Date;

  ngxSpinner: string = "Loading...";


  constructor(private constants: Constants,
    private service: AlterationsOperationalsService,
    private expService: JsonExporterService,
    private spinnerService: NgxSpinnerService,) {
      this.excelName = new Date().toISOString().slice(0, 10).replaceAll('-','') + '-alteraciones-operacionales';
     }

  ngOnInit(): void {
    this.request = new AlterOperRequest();
    this.dataSource = new MatTableDataSource<AlterOperResponse>(FLIGHTS_DATA);
    this.minDateInit=undefined;
    this.minDateEnd=undefined;
    this.maxDateEnd=undefined;
    let dated: Date = new Date();
    this.starDate = new Date();
    this.endDate = new Date();
    this.starDate.setHours(dated.getHours() - 24);
    this.endDate.setHours(dated.getHours() + 72);
    this.request.fromDate = this.starDate.toISOString().slice(0, 10);
    this.request.toDate = this.endDate.toISOString().slice(0, 10);
    this.alterOperForm = new FormGroup({
      origin: new FormControl(""),
      destination: new FormControl(""),
      typeMessage: new FormControl(""),
      flightNumber: new FormControl(""),
      startDate: new FormControl(this.starDate, Validators.required),
      endDate: new FormControl(this.endDate, Validators.required),
      typeChange: new FormControl(""),
      state: new FormControl(""),
      name: new FormControl("")
    });
    this.setAlterationOperational(this.request,0);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  clean(): void {
    this.alterOperForm = new FormGroup({
      origin: new FormControl(""),
      destination: new FormControl(""),
      typeMessage: new FormControl(""),
      flightNumber: new FormControl(""),
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
      typeChange: new FormControl(""),
      state: new FormControl(""),
      name: new FormControl("")
    });

    this.request = new AlterOperRequest();
    this.dataSource = new MatTableDataSource<AlterOperResponse>(FLIGHTS_DATA);

    this.minDateInit=undefined;
    this.minDateEnd=undefined;
    this.maxDateEnd=undefined;
  }

  search(): void {
    this.spinnerService.show();

    if (!this.alterOperForm.valid) {
      this.spinnerService.hide();
      return;
    }

    this.request.types = this.validListValue(this.alterOperForm.controls.typeMessage.value);
    this.request.departure = this.validValue(this.alterOperForm.controls.origin.value);
    this.request.arrival = this.validValue(this.alterOperForm.controls.destination.value);
    this.request.flightNumber = this.validValue(this.alterOperForm.controls.flightNumber.value);
    this.request.fromDate = this.alterOperForm.controls.startDate.value.toISOString().slice(0, 10);
    this.request.toDate = this.alterOperForm.controls.endDate.value.toISOString().slice(0, 10);
    this.request.changeManual = this.validListValue(this.alterOperForm.controls.typeChange.value);
    this.request.changeManual = this.request.changeManual.map(val => val.toString().toUpperCase())
    this.request.status = this.validListValue(this.alterOperForm.controls.state.value);
    this.request.status = this.request.status.map(val => val.toString().toUpperCase())

    this.setAlterationOperational(this.request,1);
    //this.dataSource.filter = '17';
  }

  save(): void {
    let changeOne: ChangeManual;
    let changeFilter: ChangeManual[];
    changeFilter = new Array();

    this.selection.selected.forEach((change, index) => {
      changeOne = new ChangeManual();
      changeOne.idAlterationOperational = change.id;
      changeOne.flagManual = true;
      changeOne.userEmail = localStorage.getItem('userID');
      changeFilter.push(changeOne);
    });
    this.service.saveFlagManual(changeFilter).subscribe(
      (resp: Response) => {
        if (resp.status == 'OK'){
          SweetAlertUtils.show(` (${resp.rows}) registros actualizados. `, 'success', 'Info');
          this.selection.selected.forEach((change) => {
            //this.deleteRow(change);
            change.flagManual = true;
          });
          this.selection.clear();
          this.search();
        }

      },
      (err) => {
        console.log('No se pudieron guardar los datos', err);
        //this.spinnerService.hide();
        SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
      }
    );

  }

  setAlterationOperational(request:AlterOperRequest, mode:number){
    this.service.findAlterationsOperationals(request).subscribe(
      (data: any) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection.clear();
        this.spinnerService.hide();
      },
      (err) => {
        console.log('Error recuperando información de Alteraciones Operacionales.', err);
        this.spinnerService.hide();
        if(err.status == 400){
          if (mode===1){
            SweetAlertUtils.show(`No existen registros para los filtros seleccionados.`, 'info', 'Info');
          }
        }else{
          SweetAlertUtils.showError(err, this.constants.ALTERATIONS_PROCESS_ERROR);
        }

      }
    );
  }

  hiddenElements(): void {
    console.log('Export Started');
    this.ngxSpinner = "Downloading...";
    this.spinnerService.show();

    this.showScreen=false
  }
  showElements(): void {
    console.log('Export Completed');
    this.spinnerService.hide();
    this.ngxSpinner = "Loading...";
    this.showScreen=true
  }


  toUpperCase(control){
    this.alterOperForm.get(control).setValue(this.alterOperForm.get(control).value.toUpperCase())
  }

  deleteRow(sel) {
    this.dataSource.data = this.dataSource.data.filter(i => i !== sel)
  }

  validValue(value: any) {
    if (value) {
      return value;
    } else {
      return '';
    }
  }
  validListValue(value: any) {
    if (value) {
      return value;
    } else {
      return [];
    }
  }

  toggleAllSelection(e) {
    switch (e.value) {
      case "type":
        if (e._selected) {
          this.alterOperForm.controls["typeMessage"].setValue(this.typeMessages);
          e._selected = true;
        } else {
          this.alterOperForm.controls["typeMessage"].setValue([]);
        }
        break;
      case "typeC":
        if (e._selected) {
          this.alterOperForm.controls["typeChange"].setValue(this.typeChanges);
          e._selected = true;
        } else {
          this.alterOperForm.controls["typeChange"].setValue([]);
        }
        break;
      case "statu":
        if (e._selected) {
          this.alterOperForm.controls["state"].setValue(this.status);
          e._selected = true;
        } else {
          this.alterOperForm.controls["state"].setValue([]);
        }
        break;
    }

  }

  verifyAll(value: String, e) {
    switch (value) {
      case "type":
        if (
          this.alterOperForm.controls["typeMessage"].value.length !=
          this.typeMessages.length
        ) {
          e._selected = false;
        } else {
          this.element = document.getElementById("typeMessage") as HTMLElement;
          this.element.click();
        }
        break;
      case "typeC":
        if (
          this.alterOperForm.controls["typeChange"].value.length !=
          this.typeChanges.length
        ) {
          e._selected = false;
        } else {
          this.element = document.getElementById("typeChangeOption") as HTMLElement;
          this.element.click();
        }
        break;
      case "statu":
        if (
          this.alterOperForm.controls["state"].value.length !=
          this.status.length
        ) {
          e._selected = false;
        } else {
          this.element = document.getElementById("statuOption") as HTMLElement;
          this.element.click();
        }
        break;

    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: AlterOperResponse): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  viewInfo(row?: AlterOperResponse) {
    SweetAlertUtils.show(row.detailObservation, 'info', 'Info');
  }

  reprocess(row?: AlterOperResponse) {
    SweetAlertUtils.confirm(`¿Seguro que quieres reprocesar el vuelo: ${row.carrierComercial + row.flightNumber}
    con origen-destino: ${row.dprtrAirport}-${row.arrvlAirport}`).then((result) => {
      if (result.value) {
        console.log(`reprocess ${JSON.stringify(row)}`);
        this.spinnerService.show();
        let scheduleflightDateUTC:string = new Date().getFullYear().toString() + '-' +
        row.scheduledFlightDate.substring(5,7) + '-' +   row.scheduledFlightDate.substring(8,10);
        console.log(scheduleflightDateUTC);
        let key: string = row.carrierComercial +
        scheduleflightDateUTC +
        row.flightNumber +
        row.dprtrAirport +
        row.arrvlAirport +
        row.aircraftRegistration;

        let configuration:ConfigurationChange = {
          id: row.id,
          trackId: key,
          flightDateUTC:scheduleflightDateUTC,
          airlineCode: row.carrierComercial,
          flightNumber: row.flightNumber.toString(),
          departureAirportCode: row.dprtrAirport,
          arrivalAirportCode: row.arrvlAirport,
          aircraftRegistration:row.aircraftRegistration,
          userEmail: localStorage.getItem('userID'),
          typeProcess: 'REPROCESS'
        };

        this.service.executeConfig(configuration).subscribe((data: any) => {

          if (data){
            console.log('result: ' + JSON.stringify(data));
            if(data.codeDescription.status=="OK"){
              SweetAlertUtils.show(data.codeDescription.message + ' - ' + data.codeDescription.details[0],'success','Resultado Reprocesar')
              row.status = 2;
              this.search();
            }else{
              SweetAlertUtils.show(data.codeDescription.message + ' - ' + data.codeDescription.details[0],'warning','Resultado Reprocesar')
            }
          }

        }  ,
        (err) => {
          console.log(`Error publish ${configuration}`, err);
          configuration.status = "-1";
          this.spinnerService.hide();
        },
        () => {
          console.log('complete');
          this.spinnerService.hide();
        });

      }
    });
  }

  onDateEndChange(event) {
    this.minDateInit = new Date(event.value)
    this.minDateInit.setMonth(this.minDateInit.getMonth() - 1);
  }

  onDateInitChange(event) {
    this.minDateEnd = event.value;
    this.maxDateEnd = new Date(event.value);
    this.maxDateEnd.setMonth(this.maxDateEnd.getMonth() + 1)
  }

}

const FLIGHTS_DATA: AlterOperResponse[] = []



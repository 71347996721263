import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlightInhibitionRange } from 'src/app/models/maintainers/flight-inhibition-range';
import { MaintainersService } from 'src/app/services/maintainers.service';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import { UserInfo } from 'src/app/models/login/user-info';

@Component({
  selector: 'app-flight-inhibition-range',
  templateUrl: './flight-inhibition-range.component.html',
  styleUrls: ['./flight-inhibition-range.component.scss']
})
export class FlightInhibitionRangeComponent implements OnInit, AfterViewInit  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['rangoDesde','rangoHasta'];
  dataSource: MatTableDataSource<FlightInhibitionRange>;
  isAdmin:boolean = false;
  user: UserInfo;

  constructor(private constants: Constants,
    private service: MaintainersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    let roles:any = JSON.parse(sessionStorage.getItem('userInfo'));
    this.user = new UserInfo(roles.roles);
    this.isAdmin = this.user.isAdminUser();
    if (this.isAdmin){
      this.displayedColumns.push('action');
    }   
    this.dataSource = new MatTableDataSource<any>([]);
    this.setFlightInhibitionRange();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  search(searchi:any): void {
    console.log(searchi);
    this.dataSource.filter = `${searchi}`;
  }
  
  openEdit(row: FlightInhibitionRange) {
    console.log(JSON.stringify(row));
    let data: any;

    if (row) {
      data = {
        mode: 1,
        row
      }
    } else {
      row = {id: null, rangoDesde:null, rangoHasta:null}
      data = {
        mode: 0,
        row

      }
    }

    const dialogRef = this.dialog.open(FlightInhibitionRangeDialogOverviewEdit, {
      width: '260px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined){
        return;
      }
      console.log(`The dialog was closed: ${JSON.stringify(result.row)}`);
      if(result.mode==0){
        this.service.saveFlightInhibitionRange(result.row).subscribe((response: FlightInhibitionRange) => {
          console.log(response);
          if(response){
            this.setFlightInhibitionRange();
            SweetAlertUtils.show('Datos Guardados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error creando FligthInhibition.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );

      }else if(result.mode==1){
        this.service.updateFlightInhibitionRange(data.row).subscribe((response: any) => {
          console.log(response);
          if(response){
            this.setFlightInhibitionRange();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error Actualizando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }

    });

  }

  delete(row: FlightInhibitionRange): void {
    console.log(row); 
    SweetAlertUtils.confirm(`¿Seguro que quieres eliminar el rango:  ${row.rangoDesde}-${row.rangoHasta}`).then((result) => {
      if (result.value) {
        console.log('reprocess');
        this.service.deleteFlightInhibitionRange(row).subscribe((response: FlightInhibitionRange) => {
          console.log(response);
          if(response){
            this.setFlightInhibitionRange();
            SweetAlertUtils.show('Registro Eliminado','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error Eliminando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );   
      }
    });
 
  }

  setFlightInhibitionRange() {

    this.service.findFlightInhibitionRange("").subscribe((data: FlightInhibitionRange[]) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },
      (err) => {
        console.log('Error recuperando información de inhibición por Rango vuelos.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
      }
    );
  }

}


@Component({
  selector: 'dialog-overview-edit',
  templateUrl: 'dialog-overview-edit.html'
})
export class FlightInhibitionRangeDialogOverviewEdit {
  modeDescription: string;

  constructor(
    public dialogRef: MatDialogRef<FlightInhibitionRangeDialogOverviewEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(`mode: ${data.mode}`);
    this.modeDescription = (data.mode == 0) ? 'Nueva' : 'Editar';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(myForm: NgForm) {  }

}

import { Component, OnInit, AfterViewInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MapGeneric } from 'src/app/models/maintainers/map-generic';
import { MaintainersService } from 'src/app/services/maintainers.service';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { SubFlota } from 'src/app/models/maintainers/flotas-mm';
import { Mapa } from 'src/app/models/maintainers/map';
import { CabinType } from 'src/app/models/maintainers/cabinType';
import { Flota } from 'src/app/models/maintainers/flotas-host';
import { AircraRegistration } from 'src/app/models/configuration-change/aircraft-registration';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserInfo } from 'src/app/models/login/user-info';

@Component({
  selector: 'app-maps-generic',
  templateUrl: './maps-generic.component.html',
  styleUrls: ['./maps-generic.component.scss']
})
export class MapsGenericComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['matricula', 'codigoMapa', 'descripcionMapa', 'cabin', 'flotasHost', 'flotasMm'];
  dataSource: MatTableDataSource<MapGeneric>;
  flotas: Flota[] = [];
  subFlotas: SubFlota[] = [];
  mapas: Mapa[] = [];
  cabinTypes: CabinType[] = [];
  matriculas: AircraRegistration[] = [];
  data:MapGeneric[] = [];
  dataFilter:MapGeneric[] = [];
  isAdmin:boolean = false;
  user: UserInfo;

  constructor(private constants: Constants,
    private service: MaintainersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    let roles:any = JSON.parse(sessionStorage.getItem('userInfo'));
    this.user = new UserInfo(roles.roles);
    this.isAdmin = this.user.isAdminUser();
    if (this.isAdmin){
      this.displayedColumns.push('action');
    }  
	
    this.dataSource = new MatTableDataSource<any>([]);
    this.setMapsGeneric();
    this.setMatriculas();
    this.setSubFlotas();
    this.setMapas();
    this.setCabinas();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  search(searchi: any): void {
    this.dataSource.data = this.data;
    console.log(searchi);
    //let subFlota: SubFlota = {'idFlotaMm':225,'grupoFlota':'32F'};
    //this.dataSource.filter = `${searchi}`;
    //this.dataSource.filter = `${subFlota}`;
 
    this.dataFilter = this.data.filter(map =>  map.matricula.matricula.indexOf(searchi) > -1 );


    this.dataFilter = this.dataFilter.concat(
      this.data.filter(map =>  map.mapa.codigoMapa.toString().indexOf(searchi) > -1 )
    );

    this.dataFilter = this.dataFilter.concat(
      this.data.filter(map =>  map.mapa.descripcionMapa.indexOf(searchi) > -1 )
    );

    this.dataFilter = this.dataFilter.concat(
      this.data.filter(map =>  map.mapa.tiposCabina.descripcion.indexOf(searchi) > -1 )
    );

    this.dataFilter = this.dataFilter.concat(
      this.data.filter(map =>  map.flotasMm.grupoFlota.indexOf(searchi) > -1 )
    );

    this.dataFilter = this.dataFilter.concat(
      this.data.filter(map =>  map.matricula.flotasHost.grupoFlota.indexOf(searchi) > -1 )
    );

    this.dataFilter = this.dataFilter.filter((item,index)=>{
      return (this.dataFilter.indexOf(item) == index)
   });

    //console.log(JSON.stringify(this.dataFilter));
    this.dataSource.data = this.dataFilter;

  }

  openEdit(row: MapGeneric) {
    console.log(JSON.stringify(row));
    console.log('count mapas: ' + this.mapas.length)
    let data: any;

    if (row) {
      data = { mode: 1, matriculas:this.matriculas, flotas: this.flotas, subFlotas: this.subFlotas, mapas: this.mapas, 
              cabinTypes: this.cabinTypes , flotaAndSubFlota:'A', row }
    } else {
      let flotasMm: SubFlota = {idFlotaMm:null, grupoFlota:null};
      let matricula:AircraRegistration = {idMatricula:null,matricula:null, flotasMm,flotasHost:null};
      let tipoCabina:CabinType= {idCabina:null, descripcion:null} 
      let mapa: Mapa = {idMapa:null,codigoMapa:null,descripcionMapa:null,tiposCabina:tipoCabina};
      row = { idRegla: null, matricula,  flotasMm: flotasMm, mapa: mapa, tiposCabina: tipoCabina };      
      data = { mode: 0, matriculas:this.matriculas, flotas: this.flotas, subFlotas: this.subFlotas, mapas: this.mapas, 
        cabinTypes: this.cabinTypes , flotaAndSubFlota:'B', row }
    }

    const dialogRef = this.dialog.open(MapGenericDialogOverviewEdit, {
      width: '520px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined){
        return;
      }
      
      //let subflota:SubFlota = this.subFlotas.filter(subflota =>subflota.idFlotaMm ==result.row.matricula.flotasMm.idFlotaMm)[0];
      let matricula:AircraRegistration = this.matriculas.filter(matricula =>matricula.idMatricula ===result.row.matricula.idMatricula)[0];
      let mapa:Mapa = this.mapas.filter(mapa => mapa.idMapa === result.row.mapa.idMapa)[0];

      if (matricula == null){
        console.log('Error: no se selecciono Matricula')
        console.log(JSON.stringify(matricula));
        SweetAlertUtils.show('No se pudo realizar el cambio. No se selecciono Matricula.','error','Error');  
        return;
      }
      if (matricula.flotasMm == null){
        console.log('Error: subflota no encontrada')
        console.log(JSON.stringify(matricula));
        SweetAlertUtils.show('No se pudo realizar el cambio. Subflota no encontrada.','error','Error');  
        return;
      }
      if (mapa.tiposCabina == null){
        console.log('Error: Mapa no encontrado');
        console.log(JSON.stringify(matricula));
        SweetAlertUtils.show('No se pudo realizar el cambio. Mapa no encontrado.','error','Error');  
        return;
      }
      
      result.row.flotasMm =matricula.flotasMm;
      result.row.tiposCabina = mapa.tiposCabina;
      result.row.mapa = mapa;
      console.log(`The dialog was closed: ${JSON.stringify(result.row)}`);
      
      if(result.mode==0){
        this.service.saveMapsGeneric(result.row).subscribe((response: MapGeneric) => {
          console.log(response);
          if(response){
            this.setMapsGeneric();
            SweetAlertUtils.show('Datos Guardados','info','Info');
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error');              
          }
        },
          (err) => {
            console.log('Error creando.', err);
            if (err.status == 400) {
              console.log("data not found.");
              SweetAlertUtils.show('No se pudo realizar el cambio. Mapa generico ya agregado.','error','Error');    
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );

      }else if(result.mode==1){
        this.service.updateMapsGeneric(data.row).subscribe((response: any) => {
          console.log(response);
          if(response){
            this.setMapsGeneric();
            SweetAlertUtils.show('Datos Actualizados','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error Actualizando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }

    });

  }

  delete(row: MapGeneric): void {
    console.log(row); 
    SweetAlertUtils.confirm(`¿Seguro que quieres eliminar el registro : ${row.matricula.matricula} 
    - ${row.mapa.codigoMapa} - ${row.mapa.descripcionMapa} - ${row.tiposCabina.descripcion}`)
    .then((result) => {
      if (result.value) {
        console.log('reprocess');
        this.service.deleteMapsGeneric(row).subscribe((response: any) => {
          console.log(response);
          if(response){
            this.setMapsGeneric();
            SweetAlertUtils.show('Registro Eliminado','info','Info')
          }else{
            SweetAlertUtils.show('No se pudo realizar el cambio','error','Error')              
          }
        },
          (err) => {
            console.log('Error Eliminando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );   
      }
    });

  }

  setMapsGeneric() {

    this.service.findMapsGeneric("").subscribe((data: MapGeneric[]) => {
      this.dataSource = new MatTableDataSource(data);
      this.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },
      (err) => {
        console.log('Error recuperando información de excepción por vuelos.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
      }
    );
  }

  setMatriculas() {

    this.service.findMatriculas("").subscribe((data: AircraRegistration[]) => {
      //console.log(`matriculas: ${JSON.stringify(data)}`)
      this.matriculas = data;  
    },
      (err) => {
        console.log('Error recuperando información de matriculas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.CONFIGURATION_PROCESS_ERROR);
        }
      }
    );
  }

  setFlotas() {
    this.service.findFlotasHost("").subscribe((response: Flota[]) => {
      console.log(response);
      this.flotas = response;
    },
      (err) => {
        console.log('Error recuperando información de flotas-mm.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }

  setSubFlotas() {
    this.service.findFlotasMm("").subscribe((response: SubFlota[]) => {
      console.log(response);
      this.subFlotas = response;
    },
      (err) => {
        console.log('Error recuperando información de flotas-mm.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }

  setMapas() {
    this.service.findMapas("").subscribe((response: Mapa[]) => {
      console.log(response);
      this.mapas = response;
    },
      (err) => {
        console.log('Error recuperando información de mapas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );

  }

  setCabinas() {
    this.service.findCabinTypes("").subscribe((response: CabinType[]) => {
      console.log(response);
      this.cabinTypes = response;
    },
      (err) => {
        console.log('Error recuperando información de cabinas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }
}

@Component({
  selector: 'dialog-overview-edit',
  templateUrl: 'dialog-overview-edit.html'
})
export class MapGenericDialogOverviewEdit {

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('matSelectMapa', { static: true }) matSelectMapa: MatSelect;

  protected _onDestroy = new Subject<void>();

  public matriculaCtrl: FormControl = new FormControl();
  public mapaCtrl: FormControl = new FormControl();

  public matriculaFilterCtrl: FormControl = new FormControl();
  public mapaFilterCtrl: FormControl = new FormControl();

  public filteredMatriculas: ReplaySubject<AircraRegistration[]> = new ReplaySubject<AircraRegistration[]>(1);
  public filteredMapas: ReplaySubject<Mapa[]> = new ReplaySubject<Mapa[]>(1);

  modeDescription: string;
  mapas: Mapa[] = [];
  mapasFilter: Mapa[] = [];

  constructor(
    public dialogRef: MatDialogRef<MapGenericDialogOverviewEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(`mode: ${data.mode}`);
    this.modeDescription = (data.mode == 0) ? 'Nuevo' : 'Editar';
    this.mapas = this.data.mapas;
    this.mapasFilter = this.mapas;
  }

  ngOnInit() {
    this.filteredMatriculas.next(this.data.matriculas.slice());
    this.filteredMapas.next(this.mapasFilter.slice());

    this.matriculaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMatriculas();
      });
      
    this.mapaFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMapas();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredMatriculas
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: AircraRegistration, b: AircraRegistration) => a && b && a.idMatricula === b.idMatricula;
      });

    this.filteredMapas
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if(this.matSelectMapa){
          this.matSelectMapa.compareWith = (a: Mapa, b: Mapa) => a && b && a.idMapa === b.idMapa;
        }
      });
  }

  protected filterMatriculas() {
    if (!this.data.matriculas) {
      return;
    }

    let search = this.matriculaFilterCtrl.value;
    if (!search) {
      this.filteredMatriculas.next(this.data.matriculas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMatriculas.next(
      this.data.matriculas.filter(matricula => matricula.matricula.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterMapas() {
    if (!this.mapasFilter) {
      return;
    }

    let search = this.mapaFilterCtrl.value;
    if (!search) {
      this.filteredMapas.next(this.mapasFilter.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMapas.next(
      this.mapasFilter.filter(mapa => mapa.descripcionMapa.toLowerCase().indexOf(search) > -1 || mapa.codigoMapa.toString().indexOf(search) > -1)
    );
  }
  
    onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(myForm: NgForm) {  }

  selectCabin(cabinType:CabinType){
    this.filteredMapas = new ReplaySubject<Mapa[]>(1);
    console.log('cabinType: ' + cabinType.idCabina);
    console.log('mapas: ' + this.mapas.length);
    console.log('mapasFilter: ' + this.mapasFilter.length);    
    this.mapasFilter = this.mapas;
    this.mapasFilter = this.mapasFilter.filter(mapa => mapa.tiposCabina.idCabina === cabinType.idCabina);
    console.log('mapasFilter: ' + this.mapasFilter.length);
    this.filteredMapas.next(this.mapasFilter.slice());
    

  }

}

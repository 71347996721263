import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { JsonExporterService, TxtExporterService } from 'mat-table-exporter';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { PendingService } from 'src/app/services/pending.service';
import { AlterOperResponse } from '../../../models/alterations-operationals/alter-oper-response';
import { BatchProcessResponse } from 'src/app/models/pending/batch-process-response';
import { BatchProcessRequest } from 'src/app/models/pending/batch-process-request';

@Component({
  selector: 'app-execute',
  templateUrl: './execute.component.html',
  styleUrls: ['./execute.component.scss']
})
export class ExecuteComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private constants: Constants,
    private service: PendingService,
    private expService: JsonExporterService,
    private spinnerService: NgxSpinnerService
  ) { }

  displayedColumns: string[] = ['carrierComercial', 'scheduledFlightDate',
    'flightNumber', 'dprtrAirport', 'arrvlAirport', 'matricula'];
  dataSource: MatTableDataSource<AlterOperResponse> = new MatTableDataSource<AlterOperResponse>([]);

  excelName: string;
  isLoading: boolean = false;
  titleExecute: string = "";
  time: string = "00:00:00";
  date: string = ""
  myInterval: any;
  executing: boolean = true;
  request_update: BatchProcessRequest = { idBatch: 2, lockId: 0 };


  ngOnInit(): void {
    //this.myInterval = setInterval(this.myTimer ,1000);
    this.refreshPending();
    this.refreshLastProcess();
    this.schedulePending();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  refreshPending() {
    this.spinnerService.show();
    this.isLoading = true;
    this.service.findExecute("").subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinnerService.hide();
      this.isLoading = false;
    },
      (err) => {
        console.log('Error recuperando información en pending.', err);
        this.dataSource = new MatTableDataSource<AlterOperResponse>([]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinnerService.hide();
        this.isLoading = false;
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.ALTERATIONS_PROCESS_ERROR);
        }

      }
    );
  }

  refreshLastProcess() {
    this.service.findBatchProcess('2').subscribe(
      (data: BatchProcessResponse) => {
        console.log(data);
        if (data.dtRow) {
          this.executing = (data.lockId == 1) ? true : false;
          this.titleExecute = (this.executing) ? 'Ejecutando...' : 'Último proceso terminado';
          this.time = data.dtRow.substring(12, 20);
          this.date = data.dtRow.substring(0, 11);
        }

      },
      (err) => {
        console.log('Error recuperando información de pending.', err);
        this.spinnerService.hide();
        if (err.status == 400) {
          SweetAlertUtils.show(`No se pudo recuperar la información de Pending Automático`, 'info', 'Info');
        } else {
          SweetAlertUtils.showError(err, this.constants.LOAD_ERROR);
        }

      }
    );
  }

  executeMassivePending() {
    this.service.executeMassivePending('').subscribe(
      (data: any) => {
        console.log(data);
      },
      (err) => {
        console.log('Error executeMassivePending() .', err);
        this.spinnerService.hide();
        if (err.status == 400) {
          SweetAlertUtils.show(`No se pudo recuperar la información de Pending Automático`, 'info', 'Info');
        } else {
          SweetAlertUtils.showError(err, this.constants.LOAD_ERROR);
        }

      }
    );
  }

  execute() {
    this.executing = true;
    this.titleExecute = (this.executing) ? 'Ejecutando...' : 'Último proceso terminado';
    this.executeMassivePending()
    this.schedulePending();
  }

  async schedulePending() {

    while (this.executing) {

      if (this.date){
        let ldUTC = new Date(this.date + ' ' +  this.time);
        let lastDate:any = new Date(Date.UTC(ldUTC.getFullYear(), ldUTC.getMonth(), ldUTC.getDate(), ldUTC.getHours(), ldUTC.getMinutes(), ldUTC.getSeconds()));
        console.log(lastDate.toISOString());
        let now:any = new Date();
        console.log(now.toISOString());
        let diffTime = Math.abs(now - lastDate);
        let diffHours = Math.ceil(diffTime / (3600 * 1000));
        console.log(diffHours);  
  
        if (diffHours > 5) {
          this.service.updatePendingAuto(this.request_update).subscribe(
            (data: BatchProcessResponse) => {
              console.log(data);
              this.executing = false;
            },
            (err) => {
              console.log('Error no se pudo realizar el desbloqueo.', err);
            }
          );
        }

      }
 

      await this.timeout(5000);

      this.refreshLastProcess();

    }
    this.executing = false;
  }

  refresh() {
    console.log('in refresh');
    this.refreshPending();
    //clearInterval(this.myInterval);
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  timer(ms) {
    return new Promise(resolve => setInterval(resolve, ms));
  }

  myTimer(): void {
    var d = new Date();
    console.log(this.time);
    this.time = d.toLocaleTimeString();
  }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTreeModule } from '@angular/material/tree';

import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MAT_DATE_LOCALE } from '@angular/material/core'

//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LoadingComponent } from './components/complements/loading/loading.component';
import { HomeComponent } from './components/home/home.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AlterationsOperationalsComponent } from './components/alterations-operationals/alterations-operationals.component';
import { ExecuteComponent } from './components/pending/execute/execute.component';
import { HistoryComponent } from './components/pending/history/history.component';
import { ConfigurationChangeComponent } from './components/configuration-change/configuration-change.component';
import { TrackingCommandComponent, TrackingCommandDialogOverview } from './components/tracking-command/tracking-command.component';
import { MatriculasComponent, MatriculaDialogOverviewEdit, MatriculaDialogOverviewFlota } from './components/maintainer/matriculas/matriculas.component';
import { DiagramFlowsComponent } from './components/maintainer/diagram-flows/diagram-flows.component';
import { FlightInhibitionComponent, FlightInhibitionDialogOverviewEdit } from './components/maintainer/flight-inhibition/flight-inhibition.component';
import { FlightInhibitionRangeComponent, FlightInhibitionRangeDialogOverviewEdit } from './components/maintainer/flight-inhibition-range/flight-inhibition-range.component';
import { MapsComponent, MapsDialogOverviewEdit } from './components/maintainer/maps/maps.component';
import { FlightExceptionComponent, FlightExceptionDialogOverviewEdit } from './components/maintainer/flight-exception/flight-exception.component';
import { FlightExceptionRangeComponent, FlightExceptionRangeDialogOverviewEdit } from './components/maintainer/flight-exception-range/flight-exception-range.component';
import { MapGenericDialogOverviewEdit, MapsGenericComponent } from './components/maintainer/maps-generic/maps-generic.component';
import { SftpAircomSitaComponent } from './components/sftp-aircom-sita/sftp-aircom-sita.component';
import { SftpAircomSitaTree } from './components/sftp-aircom-sita-tree/sftp-aircom-sita-tree.component';

//Services
import { LoginService } from './services/login.service';
import { SecurityService } from './services/security.service';
import { InterceptorService } from './services/interceptor.service';
import { AlterationsOperationalsService } from './services/alterations-operationals.service';
import { PendingService } from './services/pending.service';
import { ConfigurationChangeService } from './services/configuration-change.service';
import { SftpAircomSitaService } from './services/sftp-aircom-sita.service';
import { SftpUploadService } from './services/sftp-upload.service';
import { JtaUploadService } from './services/jta-upload.service';

//Modules
import { MaterialModule } from './modules/material.module';
//Constants
import { Constants } from './common/constants';
//Pipes
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SftpAircomSitaUploadComponent } from './components/sftp-aircom-sita-upload/sftp-aircom-sita-upload.component';
import { JtaUploadComponent } from './components/jta-upload/jta-upload.component';
import { CcoComponent } from './components/cco/cco.component';
import { CcoService } from './services/cco.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { PocGeComponent } from './components/poc-ge/poc-ge.component';


@NgModule({
  entryComponents: [
    MatriculaDialogOverviewEdit,
    MatriculaDialogOverviewFlota,
    FlightInhibitionDialogOverviewEdit,
    FlightInhibitionRangeDialogOverviewEdit,
    FlightExceptionDialogOverviewEdit,
    FlightExceptionRangeDialogOverviewEdit,
    MapsDialogOverviewEdit,
    MapGenericDialogOverviewEdit,
    TrackingCommandDialogOverview,
    ConfirmationDialogComponent,
    AlertDialogComponent
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LoadingComponent,
    HomeComponent,
    MainMenuComponent,
    AlterationsOperationalsComponent,
    CcoComponent,
    ExecuteComponent,
    HistoryComponent,
    ConfigurationChangeComponent,
    MatriculasComponent,
    TrackingCommandComponent,
    MatriculaDialogOverviewEdit,
    MatriculaDialogOverviewFlota,
    FlightInhibitionDialogOverviewEdit,
    FlightInhibitionRangeDialogOverviewEdit,
    FlightExceptionDialogOverviewEdit,
    FlightExceptionRangeDialogOverviewEdit,
    MapsDialogOverviewEdit,
    MapGenericDialogOverviewEdit,
    DiagramFlowsComponent,
    MapsComponent,
    FlightExceptionComponent,
    FlightExceptionRangeComponent,
    MapsGenericComponent,
    FlightInhibitionComponent,
    FlightInhibitionRangeComponent,
    TrackingCommandDialogOverview,
    SanitizeHtmlPipe,
    SftpAircomSitaComponent,
    SftpAircomSitaUploadComponent,
    SftpAircomSitaTree,
    JtaUploadComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    CreateUserComponent,
    PocGeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatListModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatTreeModule
  ],
  providers: [
    Constants,
    AlterationsOperationalsService,
    LoginService,
    SecurityService,
    PendingService,
    ConfigurationChangeService,
    SftpAircomSitaService,
    SftpUploadService,
    JtaUploadService,
    CcoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent/*, MatriculaDialogOverviewEdit*/]
})
export class AppModule { }

<style>
  :host {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Mapa Generico</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" class="row g-3" (ngSubmit)="onSubmit(myForm)" novalidate>

    <div class="col-md-12">
      <mat-form-field appearance="fill" style="width: 450px;">
        <mat-label>Matricula (Flota / SubFLota)</mat-label>
        <mat-select [(value)]="data.row.matricula" [(ngModel)]="data.row.matricula"
          name="idMatricula"
          [formControl]="matriculaCtrl" #singleSelect required>
          <mat-option>
            <ngx-mat-select-search [formControl]="matriculaFilterCtrl"
            placeholderLabel="Buscando Matricula..." 
            noEntriesFoundLabel="'no existen coincidencias'" ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let matricula of filteredMatriculas | async" [value]="matricula">
            {{matricula.matricula}} ({{matricula.flotasHost.grupoFlota}} / {{matricula.flotasMm.grupoFlota}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Cabina</mat-label>
        <mat-select 
          name="idCabina" (selectionChange)="selectCabin($event.value)"
          (ngModelChange)="data.row.mapa.idMapa = null">
          <mat-option *ngFor="let cabinType of data.cabinTypes" [value]="cabinType">
            {{cabinType.descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Mapa</mat-label>
        <mat-select [(value)]="data.row.mapa" [(ngModel)]="data.row.mapa" name="idMapa" 
          [formControl]="mapaCtrl" #matSelectMapa required>
          <mat-option>
            <ngx-mat-select-search [formControl]="mapaFilterCtrl"
            placeholderLabel="Buscando Mapa..." 
            noEntriesFoundLabel="'no existen coincidencias'" ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let mapa of filteredMapas | async" [value]="mapa">
            {{mapa.codigoMapa}}({{mapa.descripcionMapa}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between" style="width: 470px;">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data" [disabled]="!myForm.valid">
        <!--cdkFocusInitial-->
        Guardar
      </button>
    </div>
  </div>


</div>
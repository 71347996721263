import { Component, OnInit, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SecurityService } from 'src/app/services/security.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public auth2: any;
  isLoading = false;

  constructor(
    private securityService: SecurityService,
    private router: Router,
    private ngZone: NgZone,
    private cookieService: CookieService
  ) {
    this.securityService.isLoading.subscribe((value) => {
      this.ngZone.run(() => {
        this.isLoading = value;
      });
    });
  }

  ngOnInit(): void {
    if (this.cookieService.get('id_token')) {
      this.router.navigateByUrl('alte-oper');
    }
  }

  public googleInit() {
    const that = this;
    gapi.load('auth2', () => {
      that.auth2 = gapi.auth2.init({
        client_id: environment.clientId,
        cookiepolicy: 'single_host_origin',
        hosted_domain: 'latam.com',
        prompt: 'consent',
        access_type: 'offline'
      });
      that.attachSignIn(document.getElementById('googleBtn'));
    });
  }


  public attachSignIn(element) {
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        console.log('googleUser', googleUser)
        console.log('getBasicProfile', googleUser.getBasicProfile())
        console.log('v', googleUser.getAuthResponse())
        if (googleUser.getBasicProfile() !== undefined) {
          this.cookieService.set(
            'id_token',
            googleUser.getAuthResponse().id_token
          );
          this.cookieService.set(
            'googleApp',
            googleUser.getAuthResponse().access_token
          );
          this.securityService.setUser(googleUser.getBasicProfile());
        } else {
          this.attachSignIn(document.getElementById('googleBtn'));
        }
      },
      (error) => {
        console.log('error ', error);
      }
    );
  }

  ngAfterViewInit() {
    this.googleInit();
  }
}

import Swal, { SweetAlertIcon } from "sweetalert2";
import { HttpErrorResponse } from "@angular/common/http";
/*import { environment } from "../../environments/environment";
import * as _moment from "moment";
const moment = _moment;*/

export class SweetAlertUtils {

  public static showError(error: HttpErrorResponse, msg: string) {
    const statusCode =
      error.status != null && error.status !== 0 ? error.status.toString() : "";
    Swal.fire(statusCode, msg, "error");
  }

  public static show(msg: string, status: SweetAlertIcon, title: string = '') {
    return Swal.fire(title, msg, status);
  }

  public static confirm(question: string, title: string = '') {
    return Swal.fire({
      text: question,
      showCancelButton: true,
      confirmButtonColor: '#1b0088',
      cancelButtonColor: '#e63358',
      confirmButtonText: 'Ok',
      icon: 'warning'
    });
  }


  /*public static show(msg: string, status: SweetAlertIcon, title: string = '') {
    return Swal.fire(title, msg, "success");
  }*/

  public static showInfoForMasterReport() {
      Swal.fire({
      icon: "error",
      text:
        "There was an error creating the Master Report file, please contact an Administrator",
      showConfirmButton: true});
  }
}

import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SecurityService } from '../../services/security.service';
import { UserInfo } from 'src/app/models/login/user-info';
import { environment } from '../../../environments/environment';
//import { getMaxListeners } from 'node:process';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavItem } from './nav-item';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  element: HTMLElement;
  title: String = 'Pax Manager';

  userEmail: string;
  infoUser: Promise<UserInfo> = this.securityService.getUserInfoAsPromise();
  user: UserInfo;
  isViewer = false;
  isAdmin = false;
  isAdminSftp = false;
  isViewerSftp = false;
  isUploadSftp = false;
  isViewerJTA = false;

  firstSection = [];
  secondSection = [];
  isLoading = false;
  MRDownload = false;
  menu: NavItem[] = [];
  //menuChildMap = new Map();
  menuChildMap: Map<string, NavItem> = new Map<string, NavItem>();

  constructor(
    public router: Router,
    private securityService: SecurityService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.userEmail = localStorage.getItem('userID');
    this.securityService.isLoading.subscribe((value) => {
      this.isLoading = value;
    });
    this.infoUser.then((data: UserInfo) => {
      this.user = data;
      this.isViewer = this.user.isViewerUser();
      this.isViewer = this.user.isAdminUser();
      this.isAdminSftp = this.user.isAdminSftpUser();
      this.isViewerSftp = this.user.isViewerSftpUser();
      this.isUploadSftp = this.user.isUploadSftpUser();
      this.isViewerJTA = this.user.isViewerJTA();

      this.setChildenMenu(this.isViewer,
        this.isAdminSftp,
        this.isViewerSftp,
        this.isUploadSftp,
        this.isViewerJTA);

      this.setFatherMenu(this.menu, this.menuChildMap);
    });

  }

  setChildenMenu(isViewer: boolean,
    isAdminSftp: boolean,
    isViewerSftp: boolean,
    isUploadSftp: boolean,
    isViewerJTA: boolean) {
    if (isViewer) {
      this.menuChildMap.set("/alte-oper", {
        displayName: 'Alteraciones Operacionales',
        iconName:'description',
        route: '/alte-oper',
      });
      this.menuChildMap.set("/otp-app", {
        displayName: 'Otp App',
        iconName: 'flight_takeoff',
        route: '/otp-app',
      });
      this.menuChildMap.set("/create-users", {
        displayName: 'Invitar usuarios',
        iconName: 'flight_takeoff',
        route: '/create-users',
      });
      this.menuChildMap.set("/pending-execute", {
        displayName: 'Ejecución Manual',
        iconName: 'touch_app',
        route: '/pending-execute'
      });
      this.menuChildMap.set("/pending-history", {
        displayName: 'Histórico',
        iconName: 'history',
        route: '/pending-history'
      });
      this.menuChildMap.set("/diagram-flows", {
        displayName: 'Flujo mantenedores',
        iconName: 'account_tree',
        route: '/diagram-flows'
      });
      this.menuChildMap.set("/flight-inhibition", {
        displayName: 'Inhibición por Vuelo',
        iconName: 'airplanemode_inactive',
        route: '/flight-inhibition'
      });
      this.menuChildMap.set("/flight-inhibition-range", {
        displayName: 'Inhibición por Rango de Vuelo',
        iconName: 'airplanemode_inactive',
        route: '/flight-inhibition-range'
      });
      this.menuChildMap.set("/maintainer-matriculas", {
        displayName: 'Matrículas',
        iconName: 'subtitles',
        route: '/maintainer-matriculas'
      });
      this.menuChildMap.set("/maintainer-maps", {
        displayName: 'Mapas',
        iconName: 'table_chart',
        route: '/maintainer-maps'
      });
      this.menuChildMap.set("/flight-exception", {
        displayName: 'Excepciones por Vuelo',
        iconName: 'airplane_ticket',
        route: '/flight-exception'
      });
      this.menuChildMap.set("/flight-exception-range", {
        displayName: 'Excepción por Rango de Vuelo',
        iconName: 'airplane_ticket',
        route: '/flight-exception-range'
      });
      this.menuChildMap.set("/maps-generic", {
        displayName: 'Mapa Generico',
        iconName: 'table_chart',
        route: '/maps-generic'
      });
      this.menuChildMap.set("/configuration-change", {
        displayName: 'Cambios Configuración',
        iconName: 'settings',
        route: '/configuration-change',
      });
      this.menuChildMap.set("/tracking-command", {
        displayName: 'Tracking command',
        iconName: 'monitor',
        route: '/tracking-command',
      });
      
    }
    if (isViewerJTA) {
      this.menuChildMap.set("/upload-jta", {
        displayName: 'Upload JTA files',
        iconName: 'file_upload',
        route: '/upload-jta'
      });
    }
    if (isAdminSftp || isViewerSftp || isUploadSftp) {
      if (isAdminSftp || isViewerSftp) {
        this.menuChildMap.set("/sftp-download", {
          displayName: 'Descargar',
          iconName: 'file_download',
          route: '/sftp-download'
        });
      }
      if (isAdminSftp || isUploadSftp) {
        this.menuChildMap.set("/sftp-upload", {
          displayName: 'Subir',
          iconName: 'file_upload',
          route: '/sftp-upload'
        });
      }
    }
  }

  setFatherMenu(menu: NavItem[], menuChildMap: Map<string, NavItem>) {

    let listAlteOper: Array<NavItem> = new Array();
    let listPending: Array<NavItem> = new Array();
    let listMantenedores: Array<NavItem> = new Array();
    let listConfiguration: Array<NavItem> = new Array();
    let listTracking: Array<NavItem> = new Array();
    let listSftp: Array<NavItem> = new Array();
    let listOtpApp: Array<NavItem> = new Array();
    let listPocGe: Array<NavItem> = new Array();
    let listJTAapp: Array<NavItem> = new Array();
    menuChildMap.forEach((value: NavItem, key: string) => {
      if ('/alte-oper' == key) {
        listAlteOper.push(value);
      }
      if ('/pending-execute' == key
        || '/pending-history' == key) {
        listPending.push(value);
      }
      if ('/diagram-flows' == key
        || '/flight-inhibition' == key
        || '/flight-inhibition-range' == key
        || '/maintainer-matriculas' == key
        || '/maintainer-maps' == key
        || '/flight-exception' == key
        || '/flight-exception-range' == key
        || '/maps-generic' == key) {
        listMantenedores.push(value);
      }
      if ('/configuration-change' == key) {
        listConfiguration.push(value);
      }
      if(`${environment.envName}`!== 'prod'){
       if ('/otp-app' == key || '/create-users' == key) {
         listOtpApp.push(value);
       }
      }
      if ('/poc-ge' == key) {
        listPocGe.push(value);
      }
      if ('/tracking-command' == key) {
        listTracking.push(value);
      }
      if ('/sftp-download' == key
        || '/sftp-upload' == key) {
        listSftp.push(value);
      }

      if ('/upload-jta' == key) {
        listJTAapp.push(value)
      }

    });
    //Se debe ir completando los padres con la lista de children
    if (listAlteOper.length > 0) {
      menu.push(listAlteOper[0]);
    }
    if (listPending.length > 0) {
      menu.push({
        displayName: 'Pending',
        iconName: 'pending',
        children: listPending
      });
    }
    if (listMantenedores.length > 0) {
      menu.push({
        displayName: 'Mantenedores',
        iconName: 'table_rows',
        children: listMantenedores
      });
    }
     if (listOtpApp.length > 0) {
       menu.push({
         displayName: 'Otp app',
         iconName: 'flight_takeoff',
         children: listOtpApp
       });
     }
    if (listPocGe.length > 0) {
      menu.push({
        displayName: 'Poc GE',
        iconName: 'flight_takeoff',
        children: listPocGe
      });
    }
    if (listConfiguration.length > 0) {
      menu.push(listConfiguration[0]);
    }
    if (listTracking.length > 0) {
      menu.push(listTracking[0]);
    }
    if (listSftp.length > 0) {
      menu.push({
        displayName: 'SFTP',
        iconName: 'folder_open',
        children: listSftp
      });
    }
    if (listJTAapp.length > 0){
      menu.push(listJTAapp[0])
    }
  }

  logout(): void {
    Swal.fire({
      text: '¿Estas seguro que quiere salir?',
      showCancelButton: true,
      confirmButtonColor: '#1b0088',
      cancelButtonColor: '#e63358',
      confirmButtonText: 'Ok',
      icon: 'warning'
    }).then((result) => {
      if (result.value) {
        this.securityService.logOut();
      }
    });
  }

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
   labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
   laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
   voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
   cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = true;

}

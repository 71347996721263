import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders, HttpParams,HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Response } from '../models/response';
import { SftpAircomSitaResponse } from '../models/sftp-aircom-sita/sftp-aircom-sita-response';
import { SftpAircomSitaRequest } from '../models/sftp-aircom-sita/sftp-aircom-sita-request';
import { ConfigurationChange } from '../models/configuration-change/configuration-request';


@Injectable({
  providedIn: 'root'
})
export class SftpAircomSitaService {

  constructor(private constants: Constants,
    private http: HttpClient) { }

    findSftpPath(): Observable<SftpAircomSitaResponse[]> {
      const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_GET_PATH}`;
      const headers = this.addHeaders('GET');
      return this.http.get<SftpAircomSitaResponse[]>(URL, {headers});
  
    }

    findSftpFilesByPath(request: SftpAircomSitaRequest): Observable<SftpAircomSitaResponse> {
      const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_GET_FILES_BY_PATH}`;
      const headers = this.addHeaders('POST');

      return this.http.post<SftpAircomSitaResponse>(URL, request, {headers});
  
    }

    findDownloadFilesByPath(request: SftpAircomSitaRequest): Observable<any> {
      const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_GET_DOWNLOAD_FILES_BY_PATH}`;
      const headers = this.addHeaders('POST');
      const httpOptions : Object = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
           Accept: "application/zip"
        }),
        responseType: 'arraybuffer'
      };

      return this.http.post<Blob>(URL, request, httpOptions);
    }

    getDirectoriesFromSftp(forceUpdate: boolean): Observable<any>{
      const URL = forceUpdate ? 
        `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_GET_UPDATED_DIRECTORIES}` :
        `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SFTP_GET_DIRECTORIES}`;
      const headers = this.addHeaders('GET');
      return this.http.get<string[]>(URL, {headers});
    }

    addHeaders(method): HttpHeaders {
      const headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Headers', 'Content-Type');
      headers.append('Access-Control-Allow-Methods', method);
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Content-Type', 'application/json');
  
      return headers;
    }

}


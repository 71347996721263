<style>
  :host {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Inhibición por Rango vuelo</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" novalidate>

    <mat-form-field appearance="fill">
      <mat-label>Rango Desde</mat-label>
      <input matInput [(ngModel)]="data.row.rangoDesde" name="rangoDesde" required
       type="number" maxlength="10" min="0"> 
    </mat-form-field>
  
    <mat-form-field appearance="fill">
      <mat-label>Rango Hasta</mat-label>
      <input matInput [(ngModel)]="data.row.rangoHasta" name="rangoHasta" required
       type="number" maxlength="10" [min]="data.row.rangoDesde+1"> 
    </mat-form-field>

  </form>
 
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data"  [disabled]="!myForm.valid"><!--cdkFocusInitial-->
        Guardar</button>
    </div>
  </div>
  
  
</div>

<div class="container-fluid m-3">

    <div class="filters">

        <mat-card class="mat-elevation-z3">
            <mat-card-title>Cambios de Configuración Manual
                <span class="right">
                    <button mat-icon-button color="primary" aria-label="Limpiar" (click)="clean()">
                        <mat-label class="clean">Limpiar Campos </mat-label>
                        <mat-icon>backspace</mat-icon>
                    </button>
                </span>
            </mat-card-title>
            <mat-card-subtitle>Ingresa los datos solicitados para realizar cambios en la configuración
            </mat-card-subtitle>
            <mat-card-content>
                <form [formGroup]="formGroup">
                    <div class="fields row g-3">
                        <div class="col-md-3">

                            <mat-form-field class="mr-3">
                                <mat-label>Start Date</mat-label>
                                <input matInput formControlName="scheduledDateTime" [matDatepicker]="scheduledDateTime"
                                    (click)="scheduledDateTime.open()"
                                    readonly />
                                <mat-datepicker-toggle matSuffix [for]="scheduledDateTime">
                                </mat-datepicker-toggle>
                                <mat-datepicker #scheduledDateTime disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <!--mat-form-field>
                                <mat-label>Fecha UTC</mat-label>
                                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Elige fecha y hora"
                                    formControlName="scheduledDateTime" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds" [touchUi]="touchUi">
                                    <ng-template>
                                      
                                        <span>OK</span>
                                      </ng-template>
                                </ngx-mat-datetime-picker>
                            </mat-form-field-->

                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Línea aérea</mat-label>
                                <input matInput formControlName="carrierComercial" placeholder="Línea aérea"
                                    (change)="toUpperCase('carrierComercial')">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Vuelo</mat-label>
                                <input matInput formControlName="flightNumber" placeholder="Escribe el Vuelo"
                                    type="number" min=0 maxlength="4">
                            </mat-form-field>
                        </div>

                        <div class="col-md-3 buttons">
                            <button mat-flat-button color="accent" [disabled]="loading" (click)="add()">
                                <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                Agregar
                            </button>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Origen</mat-label>
                                <input matInput formControlName="origin" placeholder="Origen"
                                    (change)="toUpperCase('origin')">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="mr-3">
                                <mat-label>Destino</mat-label>
                                <input matInput formControlName="destination" placeholder="Destino"
                                    (change)="toUpperCase('destination')">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Matricula</mat-label>
                                <input type="text" matInput formControlName="matricula" [matAutocomplete]="autoGroup">
                                <mat-autocomplete #autoGroup="matAutocomplete">
                                    <!--mat-optgroup *ngFor="let group of matriculasOptions | async" [label]="group.letter"-->
                                    <ng-container *ngFor="let group of matriculasOptions | async">
                                        <mat-option *ngFor="let matricula of group.matriculas" [value]="matricula">
                                            {{matricula}}
                                        </mat-option>
                                    </ng-container>
                                    <!--/mat-optgroup-->
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3 buttons">
                            <button mat-flat-button color="primary" [disabled]="!(data.length>0)" (click)="publish()">
                                <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                Publicar
                            </button>
                        </div>

                    </div>


                    <!--div class="action">

                            <div class="search">
                                <button mat-flat-button color="accent" [disabled]="loading" (click)="search()">
                                    <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                                    Buscar
                                </button>
                            </div>
                        </div-->
                    <!--/div-->
                </form>
            </mat-card-content>
        </mat-card>

    </div>

    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-content class="p-2">
                <div class="body">
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort cdkDropList
                            cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">


                            <ng-container matColumnDef="airlineCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Línea aérea </th>
                                <td mat-cell *matCellDef="let element"> {{element.airlineCode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="flightDateUTC">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Fecha
                                    vuelo UTC </th>
                                <td mat-cell *matCellDef="let element"> {{element.flightDateUTC}} </td>
                            </ng-container>


                            <ng-container matColumnDef="flightNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Vuelo </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.flightNumber}} </td>
                            </ng-container>


                            <ng-container matColumnDef="departureAirportCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Origen </th>
                                <td mat-cell *matCellDef="let element"> {{element.departureAirportCode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="arrivalAirportCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Destino </th>
                                <td mat-cell *matCellDef="let element"> {{element.arrivalAirportCode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="aircraftRegistration">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Matricula </th>
                                <td mat-cell *matCellDef="let element" [innerHTML]="element.aircraftRegistration"></td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Estado </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="showScreen">
                                        <mat-icon *ngIf="element.status == '0'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-neutro' :  element.status == '0'}" matTooltip="Cargado">
                                            publish
                                        </mat-icon>
                                        <mat-icon *ngIf="element.status == '1'" class="semaphore" [inline]="true"
                                            [ngClass]="{'semaphore-ok' : element.status == '1'}" matTooltip="Publicado">
                                            check_circle
                                        </mat-icon>
                                        <mat-icon *ngIf="element.status == '-1'" class="semaphore" [inline]="true"
                                        [ngClass]="{'semaphore-nok' : element.status == '-1'}" [matTooltip]="element.message">
                                        cancel
                                        </mat-icon>
                                    </span>

                                </td>
                            </ng-container>


                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="format-actions">
                                        <div class="example-button-container">
                                            <button mat-mini-fab (click)="delete(element)" color="warn"
                                                aria-label="Eliminar">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>


</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
    <p style="color: white">Loading... </p>
</ngx-spinner>
import {Injectable} from '@angular/core';
import {Constants} from '../common/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {TrackCommandResponse} from '../models/tracking-command/track-command-response';
import {ChangeFilterTrackCommandRequest} from '../models/tracking-command/track-command-request';

@Injectable({
  providedIn: 'root'
})
export class TrackingCommandService {

  constructor(private constants: Constants,
              private http: HttpClient) {
  }

  findListCommandResultFilter(request: ChangeFilterTrackCommandRequest): Observable<TrackCommandResponse[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMAND_RESULT_FILTER}`;
    const headers = this.addHeaders('POST');
    console.log(JSON.stringify(request));
    return this.http.post<TrackCommandResponse[]>(URL, request, {headers});
  }

  reprocessCommand(request: any): Observable<TrackCommandResponse[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMAND_REPROCESS}`;
    const headers = this.addHeaders('POST');
    console.log(JSON.stringify(request));
    return this.http.post<TrackCommandResponse[]>(URL, request, {headers});
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    return headers;
  }

}

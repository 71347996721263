
<div class="home-container">
  <div class="row my-auto">
    <div class="col-12 col-sm-10 col-md-6">
      <mat-card class="mx-auto mat-elevation-z10">
        <div class="my-auto">
          <div class="mb-4 text-center">
            <h1 class="display-6">Home</h1>
            <img
              class="mb-4"
              src="./../../../assets/img/oneops.png"
              width="250px"
            />
          </div>
        </div>
      </mat-card>
    </div>
    <br/>
  </div>
  <!--div class="home-background">
    <div class="row h-100">
      <div class="col-12 col-sm-10 bg-blue-img"></div>
      <div class="col-sm-6 d-none d-sm-block bg-img"></div>
      
    </div>
  </div-->
</div>


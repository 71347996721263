import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DirectoryNode } from '../../models/sftp-aircom-sita/DirectoryNode';
import { TreeNode } from '../../models/sftp-aircom-sita/TreeNode';
import { FlatTreeControl } from '@angular/cdk/tree';
import { of as observableOf } from 'rxjs';

@Component({
    selector: 'app-sftp-aircom-sita-tree',
    templateUrl: './sftp-aircom-sita-tree.component.html',
    styleUrls: ['./sftp-aircom-sita-tree.component.scss']
  })

export class SftpAircomSitaTree implements OnInit, AfterViewInit {
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<TreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<DirectoryNode, TreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSourceTree: MatTreeFlatDataSource<DirectoryNode, TreeNode>;

  @Input() directories: DirectoryNode[];
  @Output() selectedPath = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<boolean>();

  activeNode: TreeNode;
  treeNodeMap : Map<TreeNode, TreeNode[]>
  treeMessage: string = "Cargando Directorios...";
  expandedNodes:string[] = [];

  constructor(){
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSourceTree = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);   
  }

  ngOnInit(): void {
    this.dataSourceTree.data = this.directories;
  }

  ngAfterViewInit(): void {
    this.dataSourceTree.data = this.directories;
  }

  //Tree Map

  /** Transform the data to something the tree can read. */
  transformer(node: DirectoryNode, level: number) {
      return {
      name: node.name,
      path: node.path,
      level: level,
      expandable: !!node.children && node.children.length > 0
      };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
      return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
      return node.expandable;
  };

  /** Get the children for the node. */
  getChildren(node: DirectoryNode) {
      return observableOf(node.children);
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: TreeNode){
      return node.expandable;
  }

  saveTreeModel(node){
    if (this.treeControl.dataNodes) {
      this.treeControl.dataNodes.forEach((dataNode: TreeNode) => {
        if (dataNode === node && this.treeControl.isExpandable(dataNode) ) {
          if(this.treeControl.isExpanded(node)){
            this.expandedNodes.push(node.path);
          }
          else{
            this.expandedNodes.pop();
          }
        }
      });
    }
  }

  setSelectedPath(node: TreeNode){
    this.selectedPath.emit(node.path);
  }

  clearTree(){
    // this.treeControl.collapseAll();
    this.activeNode = new TreeNode();
  }

  collapseTree(){
    this.treeControl.collapseAll();
  }

  setTreeMessage(message: string){
    this.treeMessage = message;
  }

  refreshTree(){
    this.refresh.emit(true);
  }

  setExpandedNodes(selectedPath: string){
    this.activeNode = this.treeControl.dataNodes.find(dataNode => dataNode.path === selectedPath);
    this.treeControl.dataNodes.filter(node => this.expandedNodes.find(path => path === node.path))
      .forEach(nodeToExpand => {
        if (!nodeToExpand) {
          return;
        } 
        if (this.treeControl.isExpandable(nodeToExpand)) {
          this.treeControl.expand(nodeToExpand);
        }
      });
  }
}


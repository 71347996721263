<div class="container py-2">
  <div class="row">
    <div class="col-md-8 mx-auto">
      <div class="card border around text-center">
        <div class="card-body">
          <div class="input-group" style="margin-bottom: 20px;">
            <div id="radioBtn" class="btn-group">
              <a class="btn btn-primary btn-md" [ngClass]="clockwork ? 'active' : ''"
                (click)="changeType('clockwork')">Clockwork</a>
              <a class="btn btn-primary btn-md" [ngClass]="legality ? 'active' : ''"
                (click)="changeType('legality')">Legality</a>
              <a class="btn btn-primary btn-md" [ngClass]="motores ? 'active' : ''"
                (click)="changeType('motores')">Motores</a>
            </div>
          </div>
          <h5 class="card-title">Upload Document</h5>
          <div class="text-center">
            <img src="assets/csv.png" style="max-width: 50px;" class="rounded" alt="...">
          </div>
          <div *ngIf="!loading">
            <div class="form-group files color">
              <label>Upload Your File .CSV</label>
              <input (change)="selectDocument($event)" type="file" class="custom-file-input" id="btn_select_csv">
            </div>
            <div class="alert alert-primary" role="alert">
              {{ nameFile }}
            </div>
          </div>
          <div *ngIf="loading" class="d-flex loading w-100">
            <mat-spinner></mat-spinner>
            <p>Subiendo archivos</p>
          </div>
          <div class="row">
            <div class="offset-md-2 col-12 col-md-4 text-center my-2">
              <button id="btn_upload" type="button" (click)="uploadFile()" [disabled]="disabledButton"
                class="btn btn-primary btn-lg btn-block">Upload</button>
            </div>
            <div class="col-12 col-md-4 text-center my-2">
              <button id="btn_cancel" type="button" (click)="clean()" [disabled]="disabledButton"
                class="btn btn-danger btn-lg btn-block">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from "../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationChange } from 'src/app/models/configuration-change/configuration-request';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ConfigurationChangeService } from 'src/app/services/configuration-change.service';

/*import * as _moment from 'moment';
import { default as _rollupMoment, Moment, MomentFormatSpecification, MomentInput } from 'moment';*/
import { AircraRegistration } from 'src/app/models/configuration-change/aircraft-registration';

//const moment = _rollupMoment || _moment;

export interface Matricula {
  letter?: string;
  matriculas: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-configuration-change',
  templateUrl: './configuration-change.component.html',
  styleUrls: ['./configuration-change.component.scss']
})
export class ConfigurationChangeComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


 /* @ViewChild('picker') picker: any;

  showSpinners = true;
  showSeconds = false;
  enableMeridian = false;
  touchUi = false;
  minDate: moment.Moment;

  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  color: ThemePalette = 'primary';

  stepHours = [1, 2, 3, 4, 5];
  stepMinutes = [1, 5, 10, 15, 20, 25];
  stepSeconds = [1, 5, 10, 15, 20, 25];*/

  displayedColumns: string[] = ['airlineCode', 'flightDateUTC', 'flightNumber',
    'departureAirportCode', 'arrivalAirportCode', 'aircraftRegistration', 'status', 'actions'];
  dataSource: MatTableDataSource<ConfigurationChange> = new MatTableDataSource<ConfigurationChange>([]);
  data: ConfigurationChange[] = [];
  request: ConfigurationChange;

  matriculasOptions: Observable<Matricula[]>;
  matriculas: Matricula[] = [/*{
  letter: "Escribe una matricula",
  matriculas: ['Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota']
}*/];
  aircraftRegistration: AircraRegistration[];

  element: HTMLElement;
  loading: boolean = false;
  formGroup: FormGroup = new FormGroup({
    scheduledDateTime: new FormControl("", Validators.required),
    carrierComercial: new FormControl("", Validators.required),
    flightNumber: new FormControl("", Validators.required),
    origin: new FormControl("", Validators.required),
    destination: new FormControl("", Validators.required),
    matricula: new FormControl("", Validators.required)
  });
  showScreen: boolean = true;



  constructor(private constants: Constants,
    private _formBuilder: FormBuilder,
    private service: ConfigurationChangeService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

    this.setMatriculas();
    if (localStorage.getItem('change_configuration')) {
      this.data = JSON.parse(localStorage.getItem('change_configuration'));
    }

  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  setMatriculas() {

    this.service.findExecute("").subscribe((data: AircraRegistration[]) => {
      console.log(data);
      this.aircraftRegistration = data;
      let matriculas: string[] = [];
      data.forEach(row => { matriculas.push(row.matricula) });
      console.log(matriculas);
      this.matriculas =
        [{
          letter: "Escribe una matricula",
          matriculas
        }];
      this.clean();
    },
      (err) => {
        console.log('Error recuperando información de matriculas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.CONFIGURATION_PROCESS_ERROR);
        }
      }
    );
  }

  clean(): void {
    console.log("clean");

    this.formGroup = this._formBuilder.group({
      scheduledDateTime: new FormControl("", Validators.required),
      carrierComercial: new FormControl("", Validators.required),
      flightNumber: new FormControl("", Validators.required),
      origin: new FormControl("", Validators.required),
      destination: new FormControl("", Validators.required),
      matricula: new FormControl("", Validators.required)
    });

    //this.request = new AlterOperRequest();
    this.dataSource = new MatTableDataSource<ConfigurationChange>(this.data);

    this.matriculasOptions = this.formGroup.get('matricula')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
  }

  add(): void {
    console.log("add");
    this.spinnerService.show();

    if (!this.formGroup.valid) {
      this.spinnerService.hide();
      return;
    }
    let key: string = this.formGroup.controls.carrierComercial.value.toString() +
      this.formGroup.controls.scheduledDateTime.value.toISOString().slice(0, 10) +
      this.formGroup.controls.flightNumber.value.toString() +
      this.formGroup.controls.origin.value.toString() +
      this.formGroup.controls.destination.value.toString() +
      this.formGroup.controls.matricula.value.toString();
    if (this.data.length > 0) {
      if (this.data.some(item => item.trackId === key)) {
        this.spinnerService.hide();
        SweetAlertUtils.show(`Ya existe una programación para este vuelo`, 'warning', 'Advertencia')
        return;
      }
    }

    let scheduledDateTime: string =  this.formGroup.controls.scheduledDateTime.value.toISOString().slice(0, 10);
    console.log(scheduledDateTime);
    this.data.push({
      id: this.data.length + 1,
      trackId: key,
      airlineCode: this.formGroup.controls.carrierComercial.value.toString(),
      flightDateUTC: scheduledDateTime,
      flightNumber: this.formGroup.controls.flightNumber.value.toString(),
      departureAirportCode: this.formGroup.controls.origin.value.toString(),
      arrivalAirportCode: this.formGroup.controls.destination.value.toString(),
      aircraftRegistration: this.formGroup.controls.matricula.value.toString(),
      status: '0',
      userEmail: localStorage.getItem('userID')
    });

    this.dataSource = new MatTableDataSource<ConfigurationChange>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    localStorage.setItem('change_configuration', JSON.stringify(this.data));

    this.spinnerService.hide();
  }

  publish(): void {
    console.log("publish");

    this.spinnerService.show();
    console.log(`dataSource: ${JSON.stringify(this.dataSource.data)}`)

    this.dataSource.data.forEach(configuration => {
      if(configuration.status == "1"){
        return;
      }
      let _aircarft: any =
        this.aircraftRegistration.filter(aircraft => aircraft.matricula === configuration.aircraftRegistration)[0];
      configuration.idAircraftRegistration = _aircarft.idMatricula;
      configuration.typeProcess = 'MANUAL';
      console.log(`data: ${_aircarft.idMatricula}`);
      
      this.service.executeConfig(configuration).subscribe((data: any) => {

        if (data){
          if(data.codeDescription.status=="OK"){
            configuration.status = "1";
          }else{
            configuration.status = "-1";
            configuration.message = data.codeDescription.message + '\n' +
            data.codeDescription.details[0];
          }
        }

      }  ,
      (err) => {
        console.log(`Error publish ${configuration}`, err);
        configuration.status = "-1";
        this.spinnerService.hide();
      },
      () => {
        console.log('complete');
        this.data = this.dataSource.data;
        console.log(`data: ${JSON.stringify(this.data)}`)
        localStorage.setItem('change_configuration', JSON.stringify(this.data));
        this.spinnerService.hide();
      }
    );
    });

  }

  toUpperCase(control) {
    console.log(control);
    this.formGroup.get(control).setValue(this.formGroup.get(control).value.toUpperCase())
  }

  delete(row?: ConfigurationChange) {
    SweetAlertUtils.confirm(`¿Seguro que quieres eliminar el vuelo: ${row.airlineCode + row.flightNumber}
    con origen-destino: ${row.departureAirportCode}-${row.arrivalAirportCode}`).then((result) => {
      if (result.value) {
        console.log('delete');

        this.data = this.data.filter(configuration => configuration.trackId !== row.trackId);
        this.dataSource.data = this.dataSource.data.filter(configuration => configuration !== row)
        localStorage.setItem('change_configuration', JSON.stringify(this.data));
        /*this.dataSource = new MatTableDataSource<ConfigurationChange>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
      }
    });
  }

  private _filterGroup(value: string): Matricula[] {
    if (value) {
      return this.matriculas
        .map(group => ({ letter: group.letter, matriculas: _filter(group.matriculas, value) }))
        .filter(group => group.matriculas.length > 0);
    }

    return this.matriculas;
  }





}


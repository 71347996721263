<div class="container-fluid m-3">
    <div class="filters">

        <mat-card class="mat-elevation-z3">
            <mat-card-title>Flujo Mantenedores</mat-card-title>
            <mat-card-subtitle>Diagrama Flujo Mantenedores</mat-card-subtitle>
        </mat-card>
    </div>

    <div class="grid row mt-4">
        <mat-card class="mat-elevation-z3 scroll">
            <mat-card-content class="p-2">
                <div class="container">
                    <table>
                        <tr>
                            <th></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/flight-inhibition">Mantenedor inhibición por vuelo</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/flight-inhibition-range">Mantenedor inhibición por rango</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/maintainer-matriculas">Carga de matriculas</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/maintainer-maps">Carga de mapas</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/flight-exception">Mantenedor excepciones por vuelo</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/flight-exception-range">Mantenedor excepciones por rango</button></th>
                            <th></th>
                            <th><button type="button" class="btn btn-info-1 btn-lg" routerLink="/maps-generic">Mantenedor mapas genéricos</button></th>
                            <th></th>
                        </tr>                        
                        <tr><br></tr>
                        <tr>
                            <th><button type="button" class="btn btn-info-3 btn-lg">Cambio de configuración</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿inhibición por vuelo?</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿inhibición por rango?</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">Matricula cargada</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿Mapa cargado?</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿Excepción por vuelo numero y/u origen destino?</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿Excepción por rango?</button></th>
                            <th><mat-icon>chevron_right</mat-icon></th>
                            <th><button type="button" class="btn btn-info-2 btn-lg">¿Mapa genérico?</button></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon>expand_more</mat-icon></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon>expand_more</mat-icon></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon>expand_more</mat-icon></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon>expand_more</mat-icon></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon>expand_more</mat-icon></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon class="error-monitor">circle</mat-icon><br>Inhibido</th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon class="error-monitor">circle</mat-icon><br>Inhibido</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon class="ok-monitor">circle</mat-icon><br>Sabre</th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon class="ok-monitor">circle</mat-icon><br>Sabre</th>
                            <th></th>
                            <th class="table-tr-th"><mat-icon class="ok-monitor">circle</mat-icon><br>Sabre</th>
                            <th></th>
                        </tr>
                    </table>
                </div>

            </mat-card-content>
        </mat-card>

    </div>
</div>
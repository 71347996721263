<div class="container-fluid m-3">

  <div class="filters">

    <mat-card class="mat-elevation-z3">
      <mat-card-title>Crear usuarios
      </mat-card-title>

      <br />

      <button mat-flat-button color="accent" [disabled]="false" (click)="inviteUsers()">
        Invitar usuarios
      </button>

    </mat-card>

  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
  <p style="color: white">{{ngxSpinner}} </p>
</ngx-spinner>

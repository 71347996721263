<ng-container *ngIf="data.state=='error'; else elseBlock">
  <div class="container-box">
    <br />
    <div class="button-container" mat-dialog-content>
      <img class="airplane-ico bold" src="../../../assets/img/Manual-servicios.png" alt="Hello Image">
      <p class="azul">{{ data.message }}</p>
    </div>
    <br />
    <div class="button-container" mat-dialog-actions>
      <button class="btn btn-error" [mat-dialog-close]="true">OK</button>
    </div>
  </div>
</ng-container>

<ng-template #elseBlock>
  <div class="container-box">
    <br />
    <div class="button-container" mat-dialog-content>
      <img class="airplane-ico bold" src="../../../assets/img/Small.png" alt="Hello Image">
      <p class="azul">{{ data.message }}</p>
    </div>
    <br />
    <div class="button-container" mat-dialog-actions>
      <button class="btn btn-ok" [mat-dialog-close]="true">OK</button>
    </div>
  </div>
</ng-template>

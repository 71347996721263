import { Component } from '@angular/core';
import { JtaUploadService } from 'src/app/services/jta-upload.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-jta-upload',
  templateUrl: './jta-upload.component.html',
  styleUrls: ['./jta-upload.component.scss']
})
export class JtaUploadComponent {

  private documentSelected: File;
  private extension: string;
  private ext: Array<string>;
  public nameFile = 'No file selected';
  private type = 'clockwork';
  clockwork: boolean = true;
  legality: boolean = false;
  motores: boolean = false;
  public disabledButton = true;
  private defaultDocumentValue;
  loading: boolean = false;

  constructor(
    private jtaUploadService: JtaUploadService
  ) {
    this.defaultDocumentValue = this.documentSelected;
  }

  changeType(dataset) {
    this.type = dataset;
    if (dataset == 'clockwork') {
      this.legality = false;
      this.motores = false;
      this.clockwork = true;
    } else if (dataset == 'legality') {
      this.clockwork = false;
      this.motores = false;
      this.legality = true;
    } else {
      this.clockwork = false;
      this.legality = false;
      this.motores = true;
    }
  }

  selectDocument(event) {
    if (event !== null) {
      this.documentSelected = event.target.files[0];
      this.nameFile = this.documentSelected.name;

      this.disabledButton = false;

      this.ext = this.documentSelected.name.split('.');
      this.extension = this.ext[this.ext.length - 1];

      if (this.extension.toLowerCase() !== 'csv') {
        swal.fire({
          title: 'error',
          text: 'Extension not valid',
          timer: 2000,
        }).then(() => {
          this.clean();
        });

      }

    } else {
      this.documentSelected = this.defaultDocumentValue;
      this.nameFile = 'No file selected';
      this.disabledButton = true;
    }
  }

  uploadFile() {
    this.loading = true;
    this.jtaUploadService.sendFile(this.documentSelected, this.type).subscribe(
      (res: { success: boolean }) => {
        this.loading = true;
      },
      err => {
        this.loading = true;
        if (err.status == 200) {
          this.loading = false;
          swal.fire({
            title: 'Success',
            text: 'Upload document success!',
            timer: 2000,
          }).then(() => {
            swal.close();
          });
        } else {
          this.loading = false;
          swal.fire({
            title: 'error',
            text: err.message,
            timer: 200,
          }).then(() => {
            swal.close();
          });
        }
      },
      () => console.log('completed.')
    )
  }

  clean() {
    this.selectDocument(null);
  }

}

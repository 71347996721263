import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AlterOperResponse } from '../models/alterations-operationals/alter-oper-response';
import { PendingHistoryRequest } from '../models/pending/hist-request';
import { PendingHistoryResponse } from '../models/pending/hist-response';
import { BatchProcessResponse } from '../models/pending/batch-process-response';
import { BatchProcessRequest } from '../models/pending/batch-process-request';

@Injectable({
  providedIn: 'root'
})
export class PendingService {

  constructor(
    private constants: Constants,
    private http: HttpClient
  ) { }

  findExecute(request: any): Observable<AlterOperResponse> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PENDING_EXECUTE}`;
    const headers = this.addHeaders('GET');
    return this.http.get<AlterOperResponse>(URL, {headers});

  }

  findHistory(request: PendingHistoryRequest): Observable<PendingHistoryResponse> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PENDING_MASSIVE_HISTORY}`;
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<PendingHistoryResponse>(URL, request , {headers});
  }

  findBatchProcess(idBatch: any): Observable<BatchProcessResponse> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PENDING_AUTO}`;
    const headers = this.addHeaders('GET');
    let params = new HttpParams();
    params = params.set('id_batch', idBatch);
    return this.http.get<BatchProcessResponse>(URL, {headers, params});
  }

  updatePendingAuto(request: BatchProcessRequest): Observable<BatchProcessResponse> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PENDING_UPDATE}`;
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<BatchProcessResponse>(URL, request , {headers});
  }

  executeMassivePending(request: any): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PENDING_EXECUTE_MASSIVE}`;
    const headers = this.addHeaders('GET');
    return this.http.get<any>(URL, {headers});

  }


  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    return headers;
  }

}

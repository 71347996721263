<div class="container-fluid m-3">
  <div class="filters">
    <mat-card class="mat-elevation-z3">
      <mat-card-title>
        Generar archivo Poc GE
      </mat-card-title>
      <br />
      <mat-card-content>
        <div class="flex">
          <form [formGroup]="contingencyForm" class="row justify-content-space-evenly">

            <div class="col-md-3">
              <mat-form-field class="mr-3">
                  <mat-label>Fecha inicio</mat-label>
                  <input required matInput [matDatepicker]="picker2" formControlName="startTime"
                      placeholder="Seleccionar fecha de inicio">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="mr-3">
                <mat-label>Fecha fin</mat-label>
                  <input required matInput [matDatepicker]="picker" formControlName="endTime"
                    placeholder="Seleccionar fecha de fin">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3 text-center">
                <div class="fields">
                  <button mat-flat-button color="accent" [disabled]="!contingencyForm.valid" (click)="generate()">
                    <span *ngIf="loading" id="download-icon" class="material-icons">refresh</span>
                    Generar
                  </button>
                </div>
            </div>
          </form>

          <div class="col-md-3 text-center">
              <div class="fields">
                <button mat-flat-button color="accent" [disabled]="hasFile" (click)="downloadFile()">
                  <span class="material-icons">file_download</span>
                  Descargar documento
                </button>
              </div>
          </div>
          <!-- <di -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise" style="position: absolute">
  <p style="color: white">{{ngxSpinner}} </p>
</ngx-spinner>

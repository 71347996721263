import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { CcoService } from 'src/app/services/cco.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';

declare const gapi: any;

@Component({
  selector: 'app-cco',
  templateUrl: './cco.component.html',
  styleUrls: ['./cco.component.scss']
})
export class CcoComponent implements OnInit {
  loading = false;
  contingencyForm: FormGroup;
  ngxSpinner = "Loading...";
  contingencyFlights: any;
  contingencyLegs: any;
  errorStations: Array<string> = [];
  airlineCode: String = "";
  flightDate: String = "";
  flightNumber: String = "";
  supportAreas: any[] = [
    { name: 'CPC', selected: false },
    { name: 'CCO', selected: false },
    { name: 'Dispatch', selected: false },
    { name: 'Crew Tracking', selected: false },
    { name: 'Cabin Crew', selected: false },
    { name: 'Flight crew', selected: false },
    { name: 'MOC', selected: false },
    { name: 'Maintenance Airport', selected: false },
    { name: 'Orig. Airport', selected: false },
    { name: 'Dest. Airport', selected: false },
    { name: 'APV-CATERING', selected: false},
    { name: 'HCC', selected: false},
    { name: 'Orig. Unico', selected: false},
    // Añade más áreas de soporte aquí
  ];

  public auth2: any;

  constructor(private http: HttpClient,
    private securityService: SecurityService,
    public router: Router,
    private loginService: LoginService,
    public dialog: MatDialog,
    private service: CcoService,
    private cookieService: CookieService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  confirmationMessage = '¿Estás seguro de realizar esta acción?';
  private initializeForm(): void {
    this.contingencyForm = new FormGroup({
      vuelo: new FormControl(''),
      origen: new FormControl(''),
      fecha: new FormControl(null, Validators.required),
    });
  }

  private formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  clean(): void {
    this.contingencyForm.reset({
      vuelo: '',
      origen: '',
      fecha: null,
    });
    this.contingencyFlights = null;
    this.contingencyLegs = null;
  }

  search(): void {
    this.spinnerService.show();
    const formValues = this.contingencyForm.value;
    if (formValues.vuelo && formValues.vuelo.length >= 2) {
      const additionalParams = {
        flightNumber: formValues.vuelo.replace(formValues.vuelo.substring(0, 2), ""),
        flightDateUtc: this.formatDate(formValues.fecha),
        airlineCode: formValues.vuelo.substring(0, 2)
      };

      this.flightNumber = formValues.vuelo.replace(formValues.vuelo.substring(0, 2), "");
      this.airlineCode = formValues.vuelo.substring(0, 2);

      this.service.getFlights(additionalParams).subscribe(
        (data: any) => {
          if (data != null) {
            this.contingencyFlights = data;
            this.contingencyLegs = data.legs;
          } else {
            this.openAlertDialog("error", "Error recuperando información");
          }

          if (formValues.origen != "" && data != null) {
            this.contingencyLegs = data.legs.filter(leg => leg.departure.airport == formValues.origen.toUpperCase());
          }
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();
          this.openAlertDialog("error", "Tu sesión ha expirado. Vuelve a iniciar sesión", true);
        }
      );
    } else {
      this.spinnerService.hide();
    }
  }

  confirmDialogGoogleChat(leg:any ): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: { title: "¿Estas seguro de crear la sala de contingencia?", message: 'Elige la opcion "Crear" para generar la sala', supportAreas: this.supportAreas },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.generateGoogleChat(leg,result);
      } else {
        this.openAlertDialog("error", "Cancelado, No se creo la sala.");
      }
    });

  }

  generateGoogleChat(leg:any,groups: any): void {

    const additionalParams = {
      airlineCode: this.airlineCode,
      flightNumber: this.flightNumber,
      departureAirport: leg.departure.airport,
      arrivalAirport: leg.arrival.airport,
      flightDate: this.contingencyFlights.flight.dateLocal,
      flightDateUtc:this.contingencyFlights.flight.dateUtc,
      accessToken: this.cookieService.get("googleApp"),
      supportAreas: groups,
      flightDateETD: leg.departure.blockOff.estimatedDateTime.utc,
      owner: this.contingencyFlights.legs[0].aircraft.owner,
      token: this.cookieService.get("id_token"),
      departureEstDtLt : leg.departure.blockOff.estimatedDateTime.local.replace(' ', 'T')
    };

    this.service.generateGoogleChat(additionalParams).subscribe(
      (data: any) => {
        console.log('generateGoogleChat: ', data)
        this.spinnerService.hide();
        if (data != null) {
          this.openAlertDialog("success", "El canal " + data.displayName + " fue creado exitosamente");
        } else {
          this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.");
        }
      },
      (err) => {
        this.spinnerService.hide();
        console.log('Error recuperando información de Alteraciones Operacionales.', err);
        this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.", true);
      }
    );
  }

  openAlertDialog(stateValue: String, messageValue: String, isClose?: boolean) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        state: stateValue,
        message: messageValue,
      },
    });

  }

  closeSpaceChat(leg:any): void{
    const additionalParams = {
      airlineCode: this.airlineCode,
      flightNumber: this.flightNumber,
      departureAirport: leg.departure.airport,
      arrivalAirport: leg.arrival.airport,
      flightDate: this.contingencyFlights.flight.dateLocal,
      flightDateUtc:this.contingencyFlights.flight.dateUtc,
      accessToken: this.cookieService.get("googleApp"),

    };

    this.service.closeSpaceChat(additionalParams).subscribe(
      (data: any) => {
        console.log('generateGoogleChat: ', data)
        this.spinnerService.hide();
          this.openAlertDialog("success", "proceso finalizado");
      },
      (err) => {
        this.spinnerService.hide();
        console.log('Error recuperando información de Alteraciones Operacionales.', err);
        this.openAlertDialog("success", "proceso finalizado con observaciones");
      }
    );
  }
  confirmDialogSlackChat(departureAirport: String, arrivalAirport: String): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: { title: "Agrega participantes a la sala", message: 'Áreas de soporte disponibles', supportAreas: this.supportAreas },
    });
    dialogRef.afterClosed().subscribe((result) => {

      if (result != false) {
        this.generateSlackChat(departureAirport, arrivalAirport, result);
      } else {
        this.openAlertDialog("error", "Cancelado, No se creo la sala.", false);
      }
    });
  }

  generateSlackChat(arrivalAirport: String, departureAirport: String, groups: any): void {

    const additionalParams = {
      airlineCode: this.airlineCode,
      flightNumber: this.flightNumber,
      departureAirport: departureAirport,
      arrivalAirport: arrivalAirport,
      flightDate: this.flightDate,
      accessToken: this.cookieService.get("googleApp"),
      supportAreas: groups,
      owner: this.contingencyFlights.legs[0].aircraft.owner,
      token: this.cookieService.get("id_token"),
    };
    this.spinnerService.show();

    this.service.generateSlackChat(additionalParams).subscribe(
      (data: any) => {
        console.log('generateSlackChat: ', data)
        this.spinnerService.hide();
        if (data != null) {
          this.openAlertDialog("success", "El canal " + data.channel.name + " fue creado exitosamente");
        } else {
          this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.");
        }
      },
      (err) => {
        this.spinnerService.hide();
        this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.");
      }
    );
  }
  pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  closeSesion() {
    this.router.navigateByUrl('login');
    this.loginService.setCurrentLoginState(false);
    this.securityService.logOut();
  }
}



import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { Constants } from "../../../common/constants";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Mapa } from 'src/app/models/maintainers/map';
import { MaintainersService } from 'src/app/services/maintainers.service';
import { SweetAlertUtils } from 'src/app/utils/SweetAlertUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { CabinType } from 'src/app/models/maintainers/cabinType';
import { UserInfo } from 'src/app/models/login/user-info';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['typeCabin', 'descripcionMapa', 'codigoMapa'];
  dataSource: MatTableDataSource<Mapa>;
  cabinTypes: CabinType[] = [];
  isAdmin: boolean = false;
  user: UserInfo;

  constructor(private constants: Constants,
    private service: MaintainersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    let roles: any = JSON.parse(sessionStorage.getItem('userInfo'));
    this.user = new UserInfo(roles.roles);
    this.isAdmin = this.user.isAdminUser();
    if (this.isAdmin) {
      this.displayedColumns.push('action');
    }
    this.dataSource = new MatTableDataSource<any>([]);
    this.setMaps();
    this.setCabinas();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  search(searchi: any): void {
    console.log(searchi);
    this.dataSource.filter = `${searchi}`;
  }

  openEdit(row: Mapa) {
    console.log(JSON.stringify(row));
    let data: any;

    if (row) {
      let config: string = row.descripcionMapa;
      let whisky: string = '';
      let yankee: string = '';

      if (row.tiposCabina.idCabina == 55) {
        whisky = config.split('/')[0];
        yankee = config.split('/')[1];
      } else if (row.tiposCabina.idCabina == 45) {
        yankee = config;
      }

      data = {
        mode: 1,
        whisky,
        yankee,
        cabinTypes: this.cabinTypes,
        row
      }
    } else {
      let cabinType: CabinType = { idCabina: null, descripcion: null }
      row = { idMapa: null, codigoMapa: null, descripcionMapa: null, tiposCabina: cabinType }
      data = {
        mode: 0,
        whisky: '',
        yankee: '',
        cabinTypes: this.cabinTypes,
        row
      }
    }

    const dialogRef = this.dialog.open(MapsDialogOverviewEdit, {
      width: '260px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined) {
        return;
      }
      console.log(`The dialog was closed: ${JSON.stringify(result.row)}`);
      if(result.whisky){
        result.row.descripcionMapa = result.whisky + '/' + result.yankee;
      }else{
        result.row.descripcionMapa = result.yankee;
      }
      
      if (result.mode == 0) {
        this.service.saveMapas(result.row).subscribe((response: Mapa) => {
          console.log(response);
          if (response) {
            this.setMaps();
            SweetAlertUtils.show('Datos Guardados', 'info', 'Info')
          } else {
            SweetAlertUtils.show('No se pudo realizar el cambio', 'error', 'Error')
          }
        },
          (err) => {
            console.log('Error creando Mapas.', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );

      } else if (result.mode == 1) {
        this.service.updateMapas(data.row).subscribe((response: Mapa) => {
          console.log(response);
          if (response) {
            this.setMaps();
            SweetAlertUtils.show('Datos Actualizados', 'info', 'Info')
          } else {
            SweetAlertUtils.show('No se pudo realizar el cambio. Mapa ya agregado.', 'error', 'Error')
          }
        },
          (err) => {
            console.log('Error Actualizando', err);
            if (err.status == 400) {
              console.log("data not found.");
            } else {
              SweetAlertUtils.showError(err, this.constants.SAVE_ERROR);
            }
          }
        );
      }

    });

  }

  setMaps() {
    this.service.findMapas("").subscribe((data: Mapa[]) => {
      if (data) {
        data.forEach( map => map.cabin = map.tiposCabina.descripcion );
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    },
      (err) => {
        console.log('Error recuperando información de inhibición por vuelos.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.PROCESS_ERROR);
        }
      }
    );
  }

  setCabinas() {
    this.service.findCabinTypes("").subscribe((response: CabinType[]) => {
      console.log(response);
      this.cabinTypes = response;
    },
      (err) => {
        console.log('Error recuperando información de mapas.', err);
        if (err.status == 400) {
          console.log("data not found.");
        } else {
          SweetAlertUtils.showError(err, this.constants.M_FLEET_PROCESS_ERROR);
        }
      }
    );
  }

}

@Component({
  selector: 'dialog-overview-edit',
  templateUrl: 'dialog-overview-edit.html'
})
export class MapsDialogOverviewEdit {
  modeDescription: string;

  constructor(
    public dialogRef: MatDialogRef<MapsDialogOverviewEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(`mode: ${data.mode}`);
    this.modeDescription = (data.mode == 0) ? 'Nuevo' : 'Editar';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(myForm: NgForm) { }

}
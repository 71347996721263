import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class LoginService {
  private loginSubject = new Subject<boolean>();
  loginState = this.loginSubject.asObservable();

  public setCurrentLoginState(value: boolean): void {
    this.loginSubject.next(value);
  }
}

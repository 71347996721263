import { MatDialog } from '@angular/material/dialog';
import { UsersService } from './../../services/users.service';
import { Component, OnInit } from '@angular/core';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  ngxSpinner: string = "Loading...";

  constructor(
    private service: UsersService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  inviteUsers() {
    this.spinnerService.show();
    this.service.createUsers().subscribe(res => {
      console.log(res)
      if (res == null) {
        this.ngxSpinner = 'Cargando...'
        this.openAlertDialog("error", "No se pudo crear la sala. Por favor, intentalo de nuevo.");
        this.spinnerService.hide();
      } else if (res.length > 0) {
        this.openAlertDialog('success', 'Usuarios invitados con éxito')
        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    })
  }

  openAlertDialog(stateValue: String, messageValue: String) {
    this.dialog.open(AlertDialogComponent, {
      data: {
        state: stateValue,
        message: messageValue,
      },
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AlterationsOperationalsComponent } from './components/alterations-operationals/alterations-operationals.component';
import { ExecuteComponent } from './components/pending/execute/execute.component';
import { HistoryComponent } from './components/pending/history/history.component';
import { ConfigurationChangeComponent } from './components/configuration-change/configuration-change.component';
import { MatriculasComponent } from './components/maintainer/matriculas/matriculas.component';
import { TrackingCommandComponent } from './components/tracking-command/tracking-command.component';
import { MapsComponent } from './components/maintainer/maps/maps.component';
import { DiagramFlowsComponent } from './components/maintainer/diagram-flows/diagram-flows.component';
import { FlightInhibitionComponent } from './components/maintainer/flight-inhibition/flight-inhibition.component';
import { FlightInhibitionRangeComponent } from './components/maintainer/flight-inhibition-range/flight-inhibition-range.component';
import { FlightExceptionComponent } from './components/maintainer/flight-exception/flight-exception.component';
import { FlightExceptionRangeComponent } from './components/maintainer/flight-exception-range/flight-exception-range.component';
import { MapsGenericComponent } from './components/maintainer/maps-generic/maps-generic.component';
import { SftpAircomSitaComponent } from './components/sftp-aircom-sita/sftp-aircom-sita.component';
import { SftpAircomSitaUploadComponent } from './components/sftp-aircom-sita-upload/sftp-aircom-sita-upload.component';
import { JtaUploadComponent } from './components/jta-upload/jta-upload.component';
import { CcoComponent } from './components/cco/cco.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { PocGeComponent } from './components/poc-ge/poc-ge.component';
import { ViewerGuard } from './guards/viewer.guard';
import { AdminGuard } from './guards/admin.guard';
import { ViewerSftpGuard } from './guards/viewer-sftp.guard';
import { UploadSftpGuard } from './guards/upload-sftp.guard';
import { ViewerJtaGuard } from './guards/viewer-jta.guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'alte-oper', component: AlterationsOperationalsComponent, canActivate: [ViewerGuard] },
  { path: 'pending-execute', component: ExecuteComponent, canActivate: [AdminGuard] },
  { path: 'pending-history', component: HistoryComponent, canActivate: [AdminGuard] },
  { path: 'configuration-change', component: ConfigurationChangeComponent, canActivate: [AdminGuard] },
  { path: 'tracking-command', component: TrackingCommandComponent, canActivate: [AdminGuard] },
  { path: 'maintainer-matriculas', component: MatriculasComponent, canActivate: [AdminGuard] },
  { path: 'maintainer-maps', component: MapsComponent, canActivate: [AdminGuard] },
  { path: 'diagram-flows', component: DiagramFlowsComponent, canActivate: [AdminGuard] },
  { path: 'flight-inhibition', component: FlightInhibitionComponent, canActivate: [AdminGuard] },
  { path: 'flight-inhibition-range', component: FlightInhibitionRangeComponent, canActivate: [AdminGuard] },
  { path: 'flight-exception', component: FlightExceptionComponent, canActivate: [AdminGuard] },
  { path: 'flight-exception-range', component: FlightExceptionRangeComponent, canActivate: [AdminGuard] },
  { path: 'maps-generic', component: MapsGenericComponent, canActivate: [AdminGuard] },
  { path: 'sftp-download', component: SftpAircomSitaComponent, canActivate: [ViewerSftpGuard] },
  { path: 'sftp-upload', component: SftpAircomSitaUploadComponent, canActivate: [UploadSftpGuard] },
  { path: 'main-menu', component: MainMenuComponent },
  { path: 'upload-jta', component: JtaUploadComponent, canActivate: [ViewerJtaGuard] },
  { path: 'otp-app', component: CcoComponent, canActivate: [AdminGuard] },
  { path: 'create-users', component: CreateUserComponent, canActivate: [AdminGuard] },
  { path: 'poc-ge', component: PocGeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

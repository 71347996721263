<style>
  :host {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Matrícula</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" novalidate>

    <mat-form-field appearance="fill">
      <mat-label>Matricula</mat-label>
      <input matInput [(ngModel)]="data.row.matricula" name="matricula" [readonly]="(data.mode==1)" required
       (ngModelChange)="data.row.matricula = $event.toUpperCase()"> 
    </mat-form-field>
  
    <mat-form-field appearance="fill">
      <mat-label>Flota</mat-label>
      <mat-select [(value)]="data.row.flotasHost.idFlotaHost" [(ngModel)]="data.row.flotasHost.idFlotaHost" name="idFlotaHost" required> 
        <mat-option *ngFor="let flota of data.flotas" [value]="flota.idFlotaHost" >
          {{flota.grupoFlota}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field appearance="fill">
      <mat-label>SubFlota</mat-label>
      <mat-select [(value)]="data.row.flotasMm.idFlotaMm" [(ngModel)]="data.row.flotasMm.idFlotaMm" name="idFlotaMm" required>
        <mat-option *ngFor="let subflota of data.subFlotas" [value]="subflota.idFlotaMm">
          {{subflota.grupoFlota}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  

  </form>
 
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data"  [disabled]="!myForm.valid"><!--cdkFocusInitial-->
        Guardar</button>
    </div>
  </div>
  
  
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';

@Injectable()
export class JtaUploadService {

  constructor(
    private constants: Constants,
    private httpClient: HttpClient) { }

  sendFile(archive: File, type) {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.JTA_WORK_PACKAGE_UPLOAD}`
    const formData = new FormData();
    formData.append('file', archive);
    formData.append('type', type);
    return this.httpClient.post(URL, formData);
  }
}

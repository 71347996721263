<div class="row">
  <div class="col-12 text-right">
    <mat-icon matTooltip="Contraer" (click)="collapseTree()">compress</mat-icon>
    <mat-icon matTooltip="Actualizar" (click)="refreshTree()">refresh</mat-icon>
  </div>
</div>
<div class="row">
  <mat-card class="mat-elevation-z3 tree-container text-vertical-align-center" *ngIf="directories.length == 0">
    <mat-card-content>
      <h5>{{treeMessage}}</h5>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-elevation-z3 tree-container" *ngIf="directories.length != 0">
    <mat-card-content>
      <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding 
          (click)="activeNode = node; setSelectedPath(node)" [ngClass]="{ 'background-highlight': activeNode === node }">
          <button mat-icon-button disabled></button>
          <mat-icon class="type-icon" >
            {{ 'folder' }}
          </mat-icon>
          {{node.name}}
        </mat-tree-node>
      
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
        (click)="activeNode = node; setSelectedPath(node)" [ngClass]="{ 'background-highlight': activeNode === node }">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name" (click)="saveTreeModel(node)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-icon class="type-icon">
            {{'folder' }}
          </mat-icon>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>
    </mat-card-content>
  </mat-card>
</div>




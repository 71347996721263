<style>
  :host {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
</style>

<h1 style="color:#1b0088;" mat-dialog-title>{{modeDescription}} Mapa</h1>
<div mat-dialog-content>
  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" novalidate>


    <mat-form-field appearance="fill">
      <mat-label>Cabina</mat-label>
      <mat-select [(value)]="data.row.tiposCabina.idCabina" [(ngModel)]="data.row.tiposCabina.idCabina" name="idCabina"
      (ngModelChange)="data.whisky = ''"
        required>
        <mat-option *ngFor="let cabinType of data.cabinTypes" [value]="cabinType.idCabina">
          {{cabinType.descripcion}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label *ngIf="(data.row.tiposCabina.idCabina==45)">
        N/A Whisky
      </mat-label>
      <mat-label *ngIf="(data.row.tiposCabina.idCabina!=45)">
        Whisky
      </mat-label>
      <input matInput [(ngModel)]="data.whisky" name="whisky" required maxlength="5" [disabled]="(data.row.tiposCabina.idCabina==45)"
        (ngModelChange)="data.whisky = $event.toUpperCase()">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Yankee</mat-label>
      <input matInput [(ngModel)]="data.yankee" name="yankee" required maxlength="5"
        (ngModelChange)="data.yankee = $event.toUpperCase()">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Codigo Mapa</mat-label>
      <input matInput [(ngModel)]="data.row.codigoMapa" name="codigoMapa" required type="number" maxlength="6" min="0">
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="p-2 bd-highlight">
      <button mat-stroked-button color="warn" (click)="onNoClick()">Volver</button>
    </div>
    <div class="p-2 bd-highlight">
      &nbsp;&nbsp;&nbsp;
    </div>
    <div class="p-2 bd-highlight">
      <button mat-raised-button color="warn" [mat-dialog-close]="data" [disabled]="!myForm.valid">
        <!--cdkFocusInitial-->
        Guardar
      </button>
    </div>
  </div>


</div>
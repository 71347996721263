import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AircraRegistration } from '../models/configuration-change/aircraft-registration';
import { FlightInhibition } from '../models/maintainers/flight-inhibition';
import { FlightInhibitionRange } from '../models/maintainers/flight-inhibition-range';
import { FlightExceptionRange } from '../models/maintainers/flight-exception-range';
import { Flota } from '../models/maintainers/flotas-host';
import { SubFlota } from '../models/maintainers/flotas-mm';
import { Mapa } from '../models/maintainers/map';
import { FlightException } from '../models/maintainers/flight-exception';
import { CabinType } from '../models/maintainers/cabinType';
import { MapGeneric } from '../models/maintainers/map-generic';

@Injectable({
  providedIn: 'root'
})
export class MaintainersService {

  constructor(
    private constants: Constants,
    private http: HttpClient
  ) { }

  //Matriculas
  findMatriculas(request: any): Observable<AircraRegistration[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.AIRCRAFT_REGISTRATION}`;
    const headers = this.addHeaders('GET');
    return this.http.get<AircraRegistration[]>(URL, { headers });
  }

  saveMatriculas(request: AircraRegistration): Observable<AircraRegistration> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_AIRCRAFT_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<AircraRegistration>(URL, request, { headers });
  }

  updateMatriculas(request: AircraRegistration): Observable<AircraRegistration> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_AIRCRAFT_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<AircraRegistration>(URL, request, { headers });
  }

  //Flotas Mm
  findFlotasMm(request: any): Observable<SubFlota[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLEET_MM}`;
    const headers = this.addHeaders('GET');
    return this.http.get<SubFlota[]>(URL, { headers });
  }

  saveFlotasMm(request: SubFlota): Observable<SubFlota> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLEET_MM_SAVE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<SubFlota>(URL, request, { headers });
  }

  //Flotas Host
  findFlotasHost(request: any): Observable<Flota[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLEET_HOST}`;
    const headers = this.addHeaders('GET');
    return this.http.get<Flota[]>(URL, { headers });
  }

  saveFlotasHost(request: Flota): Observable<Flota> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLEET_HOST_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<Flota>(URL, request, { headers });
  }

  // FlightInhibition
  findFlightInhibition(request: any): Observable<FlightInhibition[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION}`;
    const headers = this.addHeaders('GET');
    return this.http.get<FlightInhibition[]>(URL, { headers });
  }

  saveFlightInhibition(request: FlightInhibition): Observable<FlightInhibition> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<FlightInhibition>(URL, request, { headers });
  }

  updateFlightInhibition(request: FlightInhibition): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  deleteFlightInhibition(request: FlightInhibition): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_DELETE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // FlightInhibitionRange
  findFlightInhibitionRange(request: any): Observable<FlightInhibitionRange[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_RANGE}`;
    const headers = this.addHeaders('GET');
    return this.http.get<FlightInhibitionRange[]>(URL, { headers });
  }

  saveFlightInhibitionRange(request: FlightInhibitionRange): Observable<FlightInhibitionRange> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_RANGE_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<FlightInhibitionRange>(URL, request, { headers });
  }

  updateFlightInhibitionRange(request: FlightInhibitionRange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_RANGE_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  deleteFlightInhibitionRange(request: FlightInhibitionRange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_INHIBITION_RANGE_DELETE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // FlightException
  findFlightException(request: any): Observable<FlightException[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION}`;
    const headers = this.addHeaders('GET');
    return this.http.get<FlightException[]>(URL, { headers });
  }

  saveFlightException(request: FlightException): Observable<FlightException> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<FlightException>(URL, request, { headers });
  }

  updateFlightException(request: FlightException): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  deleteFlightException(request: FlightException): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_DELETE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // FlightExceptionRange
  findFlightExceptionRange(request: any): Observable<FlightExceptionRange[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_RANGE}`;
    const headers = this.addHeaders('GET');
    return this.http.get<FlightExceptionRange[]>(URL, { headers });
  }

  saveFlightExceptionRange(request: FlightExceptionRange): Observable<FlightExceptionRange> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_RANGE_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<FlightExceptionRange>(URL, request, { headers });
  }

  updateFlightExceptionRange(request: FlightExceptionRange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_RANGE_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  deleteFlightExceptionRange(request: FlightExceptionRange): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_FLIGHT_EXCEPTION_RANGE_DELETE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // Mapas
  findMapas(request: any): Observable<Mapa[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAPAS}`;
    const headers = this.addHeaders('GET');
    return this.http.get<Mapa[]>(URL, { headers });
  }

  saveMapas(request: Mapa): Observable<Mapa> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAPAS_SAVE}`;
    const headers = this.addHeaders('POST');
    console.log(URL);
    console.log(JSON.stringify(request));
    return this.http.post<Mapa>(URL, request, { headers });
  }

  updateMapas(request: Mapa): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAPAS_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // Maps Generic
  findMapsGeneric(request: any): Observable<MapGeneric[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAP_GENERIC}`;
    const headers = this.addHeaders('GET');
    return this.http.get<MapGeneric[]>(URL, { headers });
  }

  saveMapsGeneric(request: MapGeneric): Observable<MapGeneric> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAP_GENERIC_SAVE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<MapGeneric>(URL, request, { headers });
  }

  updateMapsGeneric(request: MapGeneric): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAP_GENERIC_UPDATE}`;
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  deleteMapsGeneric(request: MapGeneric): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_MAP_GENERIC_DELETE}`;
    console.log(URL);
    console.log(JSON.stringify(request));
    const headers = this.addHeaders('POST');
    return this.http.post<any>(URL, request, { headers });
  }

  // CabinTypes
  findCabinTypes(request: any): Observable<CabinType[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.M_CABIN_TYPES}`;
    const headers = this.addHeaders('GET');
    return this.http.get<CabinType[]>(URL, { headers });
  }

  addHeaders(method): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', method);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    return headers;
  }
}

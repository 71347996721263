import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { PocGeService } from 'src/app/services/poc-ge.service';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/common/constants';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-poc-ge',
  templateUrl: './poc-ge.component.html',
  styleUrls: ['./poc-ge.component.scss']
})
export class PocGeComponent implements OnInit {
  loading = false;
  hasFile = true;
  contingencyForm: FormGroup;
  ngxSpinner = "Loading...";
  contingencyFlights: any;
  contingencyLegs: any;
  errorStations: Array<string> = [];
  airlineCode: String = "";
  flightDate: String = "";
  flightNumber: String = "";
  supportAreas: any[] = [];
  fileToDownload;

  startDate = new Date(2017, 0, 1);
  endDate = new Date(2019, 0, 1);
  range = new FormGroup({
    start: new FormControl,
    end: new FormControl,
  });
  minDateInit: Date;
  minDateEnd: Date;
  maxDateEnd: Date;

  urlBase;

  constructor(private http: HttpClient,
    public dialog: MatDialog,
    private service: PocGeService,
    private spinnerService: NgxSpinnerService,
    private constants: Constants) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  onDateInitChange(event) {
    this.minDateEnd = event.value;
    this.maxDateEnd = new Date(event.value);
    this.maxDateEnd.setMonth(this.maxDateEnd.getMonth() + 1)
  }

  private initializeForm(): void {
    this.contingencyForm = new FormGroup({
      startTime: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required)
    });
  }

  generate(): void {
    this.spinnerService.show();
    const formValues = this.contingencyForm.value;
    const startTime = this.formatDate(formValues.startTime)
    const endTime = this.formatDate(formValues.endTime)
    const request = {
      startTime,
      endTime
    }
    this.urlBase = `${environment.apiUrl}${this.constants.GENERATE_FILE_POCGE}?startTime=${request.startTime}&endTime=${request.endTime}`;
    this.service.getFilePocGe(request).subscribe(
      (data: any) => {
        saveAs(data, 'GE Sample Data Requirements-ops.xlsx')
        console.log('ok', data)
        this.openAlertDialog("success", "El archivo se genero exitosamente");
        this.hasFile = false;
        this.spinnerService.hide();
      });
  }

  private formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  downloadFile() {
    saveAs(this.fileToDownload, 'GE Sample Data Requirements-ops.xlsx')
    this.openAlertDialog("success", "El archivo se descargo exitosamente");
    this.hasFile = true;
  }

  openAlertDialog(stateValue: String, messageValue: String) {
    this.dialog.open(AlertDialogComponent, {
      data: {
        state: stateValue,
        message: messageValue,
      },
    });
  }

  onDateEndChange(event) {
    this.minDateInit = new Date(event.value)
    this.minDateInit.setMonth(this.minDateInit.getMonth() - 1);
  }
}
